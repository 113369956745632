import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import Loader from "@Components/loader/Loader";
import "primeicons/primeicons.css";

const AllReviewsPage = lazy(() =>
  import("@Pages/review/all-reviews/AllReviews").then(({ AllReviewsPage }) => ({ default: AllReviewsPage }))
);

const DigitalFootPrintPageIndex = lazy(() =>
import("@Pages/digital-footprint").then(({ DigitalFootPrintPageIndex }) => ({ default: DigitalFootPrintPageIndex }))
);
const DigitalFootSignUpPageIndex = lazy(() =>
  import("@Pages/digital-footprint/sign-up/sign-up").then(({ DigitalFootSignUpPageIndex }) => ({ default: DigitalFootSignUpPageIndex }))
);
const DigitalFootprintSignupVerificationPage = lazy(() =>
import("@Pages/digital-footprint/verification/verification").then(({ DigitalFootprintSignupVerificationPage }) => ({ default: DigitalFootprintSignupVerificationPage }))
);
const DigitalFootprintSubscriptionSuccessPage = lazy(() =>
import("@Pages/digital-footprint/subscription-success/subscription-success").then(({ DigitalFootprintSubscriptionSuccessPage }) => ({ default: DigitalFootprintSubscriptionSuccessPage }))
);
import {NewReleasesPage} from "@Pages/collections/new-releases/new-releases";
// import PageNotFound from "@Components/UI/page-not-found/page-not-found";

const PageNotFound = lazy(() =>
  import("@Components/UI/page-not-found/page-not-found").then(({ PageNotFound }) => ({ default: PageNotFound }))
);
const DigitalFootprintSubscriptionFailedPage = lazy(() =>
  import("@Pages/digital-footprint/subscription-failed/subscription-failed").then(({ DigitalFootprintSubscriptionFailedPage }) => ({ default: DigitalFootprintSubscriptionFailedPage }))
);
const Home = lazy(() =>
  import("@Pages/home/Home").then(({ Home }) => ({ default: Home }))
);
const ProductDetailPage = lazy(() =>
  import("@Pages/product/detail/product-detail").then(
    ({ ProductDetailPage }) => ({ default: ProductDetailPage })
  )
);
const ProductSearchPage = lazy(() =>
  import("@Pages/product/search/product-search").then(
    ({ ProductSearchPage }) => ({ default: ProductSearchPage })
  )
);
const ProductListPage = lazy(() =>
  import("@Pages/product/list/product-list").then(({ ProductListPage }) => ({
    default: ProductListPage,
  }))
);
const VirtualJournalDetailPage = lazy(() =>
  import("@Pages/virtual-journal/detail/VirtualJournalDetail").then(
    ({ VirtualJournalDetailPage }) => ({ default: VirtualJournalDetailPage })
  )
);
const VirtualJournalIssuesPage = lazy(() =>
  import("@Pages/virtual-journal/issues/VirtualJournalIssues").then(
    ({ VirtualJournalIssuesPage }) => ({ default: VirtualJournalIssuesPage })
  )
);
const CartPage = lazy(() =>
  import("@Pages/cart/cart").then(({ CartPage }) => ({ default: CartPage }))
);


const WriteReview = lazy(() =>
  import("@Pages/review/write-review/WriteReview").then(({ WriteReview }) => ({
    default: WriteReview,
  }))
);

const AuthorsList = lazy(() =>
  import("@Pages/authors/author-list/AuthorsList").then(({ AuthorsList }) => ({
    default: AuthorsList,
  }))
);

const AuthorDetails = lazy(() =>
  import("@Pages/authors/author-detail/AuthorDetails").then(
    ({ AuthorDetails }) => ({ default: AuthorDetails })
  )
);
const CustomerReviewSuccess = lazy(() =>
  import(
    "@Components/custom/customer-reviews/customer-review-success/customer-review-success"
  ).then(({ CustomerReviewSuccess }) => ({ default: CustomerReviewSuccess }))
);
const SampleIssues = lazy(() =>
  import("@Components/custom/virtual-journal/sample-issues/sample-issue").then(
    ({ SampleIssues }) => ({ default: SampleIssues })
  )
);


export default function AppRoutes() {
  return (
    <>
      <Routes>

        {/* add all routes */}
        <Route
          path={AppRouteURL.WRITE_REVIEW + ':editionId/write-a-review/'}
          element={
            <React.Suspense fallback={<Loader />}>
              <WriteReview />
            </React.Suspense>
          }
        />
          {/* add all routes */}
          <Route
              path={AppRouteURL.ALL_REVIEWS  + ':editionId/all-reviews/'}
              element={
                  <React.Suspense fallback={<Loader />}>
                      <AllReviewsPage />
                  </React.Suspense>
              }
          />
        {/* add all routes */}
        <Route
          path={AppRouteURL.WRITE_REVIEW_SUCCESS}
          element={
            <React.Suspense fallback={<Loader />}>
              <CustomerReviewSuccess />
            </React.Suspense>
          }
        />
        {/* add all routes */}
        <Route
          path={AppRouteURL.VIRTUAL_JOURNAL_SAMPLE_ISSUES + ":editionId"}
          element={
            <React.Suspense fallback={<Loader />}>
              <SampleIssues />
            </React.Suspense>
          }
        />

        {/* add all routes */}
        <Route
          path={AppRouteURL.PRODUCT_SEARCH_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductSearchPage />
            </React.Suspense>
          }
        />

        {/* add all routes */}
        <Route
          path={AppRouteURL.CART}
          element={
            <React.Suspense fallback={<Loader />}>
              <CartPage />
            </React.Suspense>
          }
        />

        <Route
          path={AppRouteURL.HOME}
          element={
            <React.Suspense fallback={<Loader />}>
              <Home />
            </React.Suspense>
          }
        />

          <Route
              path={AppRouteURL.PRODUCT_LIST_JOURNAL}
              element={
                  <React.Suspense fallback={<Loader />}>
                      <ProductListPage />
                  </React.Suspense>
              }
          />
        {/* add all routes */}

          <Route
              path={AppRouteURL.PRODUCT_LIST + ":by_category/all"}
              element={
                  <React.Suspense fallback={<Loader />}>
                      <ProductListPage />
                  </React.Suspense>
              }
          />
        <Route
          path={AppRouteURL.PRODUCT_LIST + ":by_category/subject/:by_subject"}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURL.PRODUCT_LIST + ":by_category/course/:by_course"}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURL.PRODUCT_LIST + ":by_category/standard/:by_grade"}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />
          <Route
              path={AppRouteURL.PRODUCT_LIST + ":by_category/institute/:by_institute"}
              element={
                  <React.Suspense fallback={<Loader />}>
                      <ProductListPage />
                  </React.Suspense>
              }
          />
        <Route
          path={AppRouteURL.PRODUCT_LIST + ":by_category/exam/:by_exam"}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURL.PRODUCT_LIST_COLLECTION + ":by_collection"}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURL.RESEARCH_OFFLINE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURL.COMPLIANCE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />
        <Route
          path={AppRouteURL.JOURNAL_ISSSUE + ":by_parentJournalId"}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />

        <Route
          path={AppRouteURL.PRODUCT_LIST_BOOKMANN_INDIA}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductListPage />
            </React.Suspense>
          }
        />
        {/* add all routes */}
        <Route
          path={AppRouteURL.PRODUCT_DETAIL + ":editionId"}
          element={
            <React.Suspense fallback={<Loader />}>
              <ProductDetailPage />
            </React.Suspense>
          }
        />

        {/* add all routes */}
        {/*<Route*/}
        {/*    path={AppRouteURL.VIRTUAL_BOOK_LIST}*/}
        {/*    element={*/}
        {/*        <React.Suspense fallback={<Loader/>}>*/}
        {/*            <VirtualJournalListIndexPage/>*/}
        {/*        </React.Suspense>*/}
        {/*    }*/}
        {/*/>*/}

        {/* add all routes */}
        {/*<Route*/}
        {/*    path={AppRouteURL.VIRTUAL_BOOK_LIST}*/}
        {/*    element={*/}
        {/*        <React.Suspense fallback={<Loader/>}>*/}
        {/*            <VirtualJournalListIndexPage/>*/}
        {/*        </React.Suspense>*/}
        {/*    }*/}
        {/*/>*/}

        {/* add all routes */}
        <Route
          path={AppRouteURL.VIRTUAL_BOOK_DETAIL}
          element={
            <React.Suspense fallback={<Loader />}>
              <VirtualJournalDetailPage />
            </React.Suspense>
          }
        />

        {/* add all routes */}
        <Route
          path={AppRouteURL.VIRTUAL_BOOK_ISSUES}
          element={
            <React.Suspense fallback={<Loader />}>
              <VirtualJournalIssuesPage />
            </React.Suspense>
          }
        />


        <Route
          path={AppRouteURL.DIGITAL_FOOTPRINT}
          element={
            <React.Suspense fallback={<Loader />}>
              <DigitalFootPrintPageIndex/>
            </React.Suspense>
          }
        >
            <Route
                path={""}
                element={
                    <React.Suspense fallback={<Loader />}>
                        <DigitalFootSignUpPageIndex/>
                    </React.Suspense>
                }
            />
            <Route
                path={"verification"}
                element={
                    <React.Suspense fallback={<Loader />}>
                        <DigitalFootprintSignupVerificationPage/>
                    </React.Suspense>
                }
            />
            <Route
                path={"success"}
                element={
                    <React.Suspense fallback={<Loader />}>
                        <DigitalFootprintSubscriptionSuccessPage/>
                    </React.Suspense>
                }
            />
            <Route
                path={"failed"}
                element={
                    <React.Suspense fallback={<Loader />}>
                        <DigitalFootprintSubscriptionFailedPage/>
                    </React.Suspense>
                }
            />
        </Route>
        <Route
          path={AppRouteURL.LATEST_RELEASES}
          element={
            <React.Suspense fallback={<Loader />}>
              <NewReleasesPage/>
            </React.Suspense>
          }
        />



        {/* add all routes */}
        <Route
          path={AppRouteURL.AUTHORS_LIST}
          element={
            <React.Suspense fallback={<Loader />}>
              <AuthorsList />
            </React.Suspense>
          }
        />

        {/* add all routes */}
        <Route
          path={`${AppRouteURL.AUTHORS_LIST}/:Id`}
          element={
            <React.Suspense fallback={<Loader />}>
              <AuthorDetails />
            </React.Suspense>
          }
        />
        {/* Page Not Found */}
        <Route
          path={AppRouteURL.PAGE_NOT_FOUND}
          element={
            <React.Suspense fallback={<Loader />}>
              <PageNotFound/>
            </React.Suspense>
          }
        />

      <Route path='*' element={<Navigate to={AppRouteURL.PAGE_NOT_FOUND} />} />
      </Routes>
    </>
  );
}
