import {useEffect, useState} from "react";
import {getData} from "@Services/bookstore/global.service";
import {APIEnv} from "@Services/bookstore/baseInterfaces";
import {useAppDispatch} from "@App/hooks/hooks";
import {hideSpinner, showSpinner} from "@App/reducers/global/globalSlice";

export const useGet = (url: string, queryParams?: any, urlParam?: any, APIEnvironment: APIEnv = APIEnv.NODE_BOOKSTORE, reFetch: any = undefined) => {
    const [data, setData] = useState<any>();
    const [error, setError] = useState<any>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(showSpinner())
        if (reFetch != null || reFetch != undefined) {

            getData(url, queryParams, urlParam, APIEnvironment)
                .then(setData)
                .catch(setError)
                .finally(() => {
                    dispatch(hideSpinner())
                });
        }
    }, [reFetch]);

    return {data, error};
};
