// import toast from "react-hot-toast";
import {APIEnv, APIResponse} from "@Services/bookstore/baseInterfaces";
import {NodeBookStoreBaseService} from "@Services/bookstore/base-services/NodeBookstoreBaseService";
import {DotNetBaseService} from "@Services/bookstore/base-services/DotNetBaseService";
import {NodeDashboardBaseService} from "@Services/bookstore/base-services/NodeDashboardBaseService";
import { NodEndmileBaseService } from "./base-services/NodEndmileBaseService";
import { RedisBaseService } from "./base-services/RedisBaseService";
import { NodeStudentBaseService } from "./base-services/nodeStudentBaseService";

const ApiService = {
    fetchData(param:any, ENV: APIEnv = APIEnv.NODE_BOOKSTORE) {

        if (ENV === APIEnv.NODE_BOOKSTORE) {
            return new Promise<APIResponse>((resolve, reject) => {

                NodeBookStoreBaseService(param).then((response: { data: APIResponse | PromiseLike<APIResponse>; }) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }

        if (ENV === APIEnv.NODE_DASHBOARD){

            return new Promise<APIResponse>((resolve, reject) => {
                NodeDashboardBaseService(param).then((response: { data: APIResponse | PromiseLike<APIResponse>; }) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }

        if (ENV === APIEnv.NODE_STUDENT){

            return new Promise<APIResponse>((resolve, reject) => {
                NodeStudentBaseService(param).then((response: { data: APIResponse | PromiseLike<APIResponse>; }) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }

        if (ENV === APIEnv.DOT_NET){
            return new Promise<APIResponse>((resolve, reject) => {
                DotNetBaseService(param).then((response: { data: APIResponse | PromiseLike<APIResponse>; }) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }

        if (ENV === APIEnv.ENDMILE) {
            return new Promise<APIResponse>((resolve, reject) => {

                NodEndmileBaseService(param).then((response: { data: APIResponse | PromiseLike<APIResponse>; }) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }

        if (ENV === APIEnv.REDIS) {
            return new Promise<APIResponse>((resolve, reject) => {

                RedisBaseService(param).then((response: { data: APIResponse | PromiseLike<APIResponse>; }) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }
        if (ENV === APIEnv.MARKETING) {
            return new Promise<APIResponse>((resolve, reject) => {

                RedisBaseService(param).then((response: any ) => {
                    resolve(response?.data)
                }).catch((errors: any) => {
                    // toast.error(errors?.response?.data?.StatusMsg || '' + " " +errors?.response?.data?.data|| '' )
                    reject(errors)
                })
            })
        }

    }
}

export default ApiService