import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDataFromLocalStorage, getIdFromStarting, removeDataFromLocalStorage, triggerGA4Event } from "@Src/utils/globalUtilities";
import { allowedCheckoutDataUrls, ProductSchemas } from "@Constants/constants";
import { SeoHandlerClass } from "@Src/utils/seo-handler.class";
import { Helmet } from "react-helmet";
import { IndexDbEvents } from '../index-db-events/index-db-events';

export const AUTH_ROUTES = [
    "/user/",
    "/cart",
    "/checkout/",
    "/payment/failed",
    "/thank-you",
    "/write-review",
    "/store/invoice/",
    "/trackorder",
];
export const UNAUTH_ONLY_ROUTES = ["/auth"];


export const RouteChangeSubscription = () => {
    // Extracts pathname property(key) from an object

    const [seoDetails, setSeoDetails] = useState<any>(null)
    const [structuredJSON, setStructuredJSON ] = useState<any>();
    const { pathname } = useLocation();
    const navigate = useNavigate();



    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        // ReactGA.pageview(pathname);
        triggerGA4Event('event', 'click', 'page_visited', {
            page_title: pathname
        })
        window.scrollTo(0, 0);
        checkAndRemoveCheckoutData(pathname);
        if( pathname.includes('/product/') ){
               let pathArr =   pathname.split('/');
               console.log("patharr", pathArr);
               if( pathArr && pathArr.length){
               let product = pathArr[2];
               if(product){
                let productId:string = getIdFromStarting(product);
                let JSONProduct='';
                let productSchema = ProductSchemas[productId];
                if(productSchema){
                    JSONProduct = productSchema;
                    setStructuredJSON(JSONProduct)
                }
               }
            }
        }
        (async () => {
            const seoHandler = new SeoHandlerClass(pathname);
            setSeoDetails({ ...await seoHandler.getSeoDetails(pathname) });

        })();
    }, [pathname]);

    useEffect(() => {
        const token = getDataFromLocalStorage("TaxmannAuthorization");
        if (token) {
            let isUnAuthOnlyRoute = UNAUTH_ONLY_ROUTES.some(el => location.pathname.includes(el))
            if (isUnAuthOnlyRoute) {
                // let user navigate as intended
            }
        } else {
            let isAuthOnlyRoute = AUTH_ROUTES.some(el => location.pathname.includes(el))
            if (isAuthOnlyRoute) {
                window.open('/gp/auth/login', '_self');
            }
        }
        let netCorePayload = {
            pageUrl: window.location.href
        }
    }, [])


    const checkAndRemoveCheckoutData = (pathName: any) => {
        const subArr = allowedCheckoutDataUrls.filter((str: any) => pathName.includes(str));

        if (subArr?.length <= 0) {
            removeDataFromLocalStorage('checkoutData')
        }
    }

    return (<>
        <Helmet>
            <title>{seoDetails?.title || 'Taxmann'}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="keywords" content={seoDetails?.keywords} />
            <meta name="description" content={seoDetails?.description} />
            <meta name="robots" content={location.hostname.indexOf('www') !== -1  ? (seoDetails?.robots || 'noindex, nofollow'):  'noindex, nofollow'  }/>
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="https://www.taxmann.com" />
            <meta property="og:title" content={seoDetails?.og?.title || seoDetails?.title} />
            <meta property="og:description" content={seoDetails?.og?.description || seoDetails?.description} />
            <meta property="og:image" content={seoDetails?.og?.image || seoDetails?.image || 'https://d3lzbbhyvqc4k.cloudfront.net/images-webp/bookstore-logo.webp'} />
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="300" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="taxmann.com" />
            <meta property="twitter:url" content={window.location.href} />
            <meta name="twitter:site" content="@taxmannindia" />
            <meta name="twitter:title" content={seoDetails?.og?.title || seoDetails?.title} />
            <meta name="twitter:description" content={seoDetails?.og?.description || seoDetails?.description} />
            <meta name="twitter:image" content={seoDetails?.og?.image || seoDetails?.image || 'https://d3lzbbhyvqc4k.cloudfront.net/images-webp/bookstore-logo.webp'} />
            
            <script className='structured-data-list' type="application/ld+json">
            {structuredJSON}
            </script>
        </Helmet>
        <IndexDbEvents pathname={pathname} />
    </>)
}