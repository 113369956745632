import jwt_decode from "jwt-decode";
import jwt_encode from "jwt-encode";
import moment from "moment";
import DOMPurify from "dompurify";
import {
  ProductBadgeEnum,
  VALIDATION_REGEX_PATTERN,
  apiConstants,
  CheckoutInformation,
  urlPrefix,
  returnURL, HeightAdjustElementType, CART_PRODUCT_TYPE, DeviceType, sharableBookStoreUrlSeparator,
  environments,
} from "@Constants/constants";
import { getSelectorsByUserAgent, useDeviceData } from "react-device-detect";
import { toast } from "react-hot-toast";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import ReactGA from 'react-ga';
import { LocalStorageDataModel } from "@Constants/api-constants/local-storage-data-model";
import {getData, postData} from "@Services/bookstore/global.service";
import {AUTH_API_CONSTANTS} from "@Constants/api-constants/auth/auth.constants";
import {APIEnv, APIStatus} from "@Services/bookstore/baseInterfaces";
import { FilterMapping } from "@Components/UI/filters/constants/filters.constants";
import CryptoJS from "crypto-js";

// let userMindlerAuthKey =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlzbWFpbGtoYW4xM0BtaW5kbGVyLmNvbSIsImF1dGhfaWQiOjI4MjEyNiwiaXNfYWx1bW5pIjoxLCJpYXQiOjE2NTgxMjUwNzV9.oVOXjQdRdwlSQ34vF1yM_lt25XziYDIyFrRYSPKY-cs";

// export const encodeString = (value: any) => {
//   return jwt_encode(value, Env().JWT_ENCODE_SECRECT || "");
// };

export const decodeString = (value: string) => {
  return jwt_decode(value);
};

// export const encodeKeyAndUrl = (route: string) => {
//   const decodedObj: any = decodeString(userMindlerAuthKey);
//   decodedObj["url"] = route;
//   return encodeString(decodedObj);
// };

export const setDataOnLocalStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const setDataOnLocalStorageWithExpiry =(key:string, value:string, ttl:any) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
    date: now.toLocaleDateString()
  }
  return localStorage.setItem(key, JSON.stringify(item))
}

export const getDataFromLocalStorage = (key: string) => {
  var value = localStorage.getItem(key);
  if (value === null) {
    return "";
  }
  return value;
};

export const removeDataFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
  return;
};

export const isMobile = () => {
  let mediaQueryObj = window.matchMedia("(max-width: 950px)");
  return mediaQueryObj.matches;
};

export const groupByAlphabet = (rawData: Array<any>): Array<any> => {
  return rawData?.reduce((r, e) => {
    // get first letter of name of current element
    let group = e?.name[0]?.toString()?.toUpperCase();
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] };
    // if there is push current element to children array for that letter
    else r[group]?.children?.push(e);
    // return accumulator
    return r;
  }, {});
};

export const getPodcastDateByMoment = (value: any) => {
  let month = moment(value).format("MMMM").toUpperCase();
  let date = moment(value).format("DD");
  let year = moment(value).format("YY");
  return `${month} ${date}, ${year}`;
};

export const checkValidFileExtensions = (
  inputFileArray: any[],
  allowedFileExtensions: string[]
): boolean => {
  let isValid = false;
  for (let i = 0; i < allowedFileExtensions.length; i++) {
    const splitArray = inputFileArray[0].name?.split(".");
    if (
      splitArray[splitArray.length - 1]?.toString()?.toLowerCase() ==
      allowedFileExtensions[i]?.toString()?.toLocaleLowerCase()
    ) {
      isValid = true;
    }
  }
  return isValid;
};

export const customCheckValidFileSize = (
  inputFileArray: any[],
  MAX_FILE_SIZE: number
): boolean => {
  let isValid = true;
  for (let i = 0; i < inputFileArray.length; i++) {
    const fileSizeKiloBytes = inputFileArray[i].size;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      isValid = false;
    }
  }
  return isValid;
};

const sanitizedData = (data: any) => ({
  __html: DOMPurify.sanitize(data),
});

export const getBadgeClassByBadgeEnum = (Enum: string): string => {
  if (Enum === ProductBadgeEnum.PREORDER) {
    return "pre-order-tag";
  } else if (Enum === ProductBadgeEnum.BESTSELLER) {
    return "best-seller-tag";
  } else if (Enum === ProductBadgeEnum.POPULAR) {
    return "popular-tag";
  } else if (Enum === ProductBadgeEnum.NEWRELEASE) {
    return "new-releases-tag";
  } else if (Enum === ProductBadgeEnum.TRENDING) {
    return "trending-tag";
  }
  return "best-seller-tag";
};

export const getAgentData = (ipAddress: string) => {
  const deviceData = useDeviceData(navigator.userAgent);
  const obj = {
    [apiConstants.ipAddress]: ipAddress,
    [apiConstants.userAgent]: navigator.userAgent,
    [apiConstants.device]: "Unknown",
    [apiConstants.os]: deviceData?.os?.name,
    [apiConstants.osVersion]: deviceData?.os?.version,
    [apiConstants.browser]: deviceData?.browser?.name,
    [apiConstants.browserVersion]: deviceData?.browser?.version,
    // [apiConstants.deviceType]: deviceData?.device?.model,
    [apiConstants.deviceType]: "DESKTOP",
  };
  return obj;
};

export const validataLoginData = (data: any) => {
  if (!data?.[apiConstants.email]) {
    toast.error("Email is required");
    return;
  } else {
    if (
      !VALIDATION_REGEX_PATTERN.emailPattern.test(data?.[apiConstants.email])
    ) {
      toast.error("Invalid email address");
      return;
    }
  }
  if (!data?.[apiConstants.password]) {
    toast.error("Password is required");
    return;
  }
  if (data?.[apiConstants.email] && data?.[apiConstants.password]) {
    return true;
  }
};

export function initializeCheckoutData() {
  let checkoutData: CheckoutInformation = {
    checkoutCart: "",
    cartSource: "C",
    billingId: "",
    shippingId: "",
    type: CART_PRODUCT_TYPE.BOOKSTORE
  };

  setDataOnLocalStorage("checkoutData", JSON.stringify(checkoutData));
}

export const checkIsUserLoggedIn = () =>
  getDataFromLocalStorage("TaxmannAuthorization");

export const actionForUnAuthUser = () => {
  if (!checkIsUserLoggedIn()) {
    setDataOnLocalStorage(returnURL, location.pathname);
    login()
    window.open(`${urlPrefix + "/" + AppRouteURL.LOGIN}`, "_self");
    return false;
  } else {
    return true;
  }
};

export const adjustViewHeight = (
    key: string,
    type: Number,
    panelType?: string,
    forceFlag?: boolean,
    mobminusHeight: number = 0,
    minusHeight: number = 0,
    plusHeight: number = 0
) =>  {
  if (isMobile() && !forceFlag) {
    return
  }
  try {
    let selector
    if (type === HeightAdjustElementType.HTML_ELEMENT) {
      selector = key
    } else if (type === HeightAdjustElementType.HTML_CLASS) {
      selector = `.${key}`
    } else if (type === HeightAdjustElementType.HTML_ID) {
      selector = `#${key}`
    } else {
      selector = key
    }

    let arr = document.querySelectorAll(selector)
    if (selector && arr && arr.length) {
      let windowHeight = window.innerHeight
      Array.from(arr).forEach((element: any) => {

        let elementTop = element.getBoundingClientRect().top
        if (elementTop > 0) {
          let remainingHeight = +windowHeight - +elementTop
          if (remainingHeight > 0) {
            if (panelType === 'middle') {
              element.style.height = `${remainingHeight - 15}px`
            } else if (isMobile()) {
              element.style.height = `${remainingHeight - 15 - mobminusHeight + plusHeight}px`
            } else {
              element.style.height = `${remainingHeight - 15 - minusHeight + plusHeight}px`
            }
          }
        }
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const toTitleCase = (str: string) => {
  return str?.replace(
      /\w\S*/g,
      function(txt) {
        return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
      }
  );
}

export const getIdFromStarting = (stringWithIdInFront: string): string => {
  if (stringWithIdInFront) {
    const a: any[] = stringWithIdInFront?.split('-');
    if (a?.length > 0) {
      return a[0];
    }
  }
    return '';
}
export const isitstudentcourse = (stringWithIdInFront: any ,filterInputKey:any): any => {
  if (stringWithIdInFront) {
    const a: any[] = stringWithIdInFront?.split('-');
    if (a?.length > 0) {
      if((a[1] == 'ca' || a[1] == 'cs' || a[1] == 'cma') && filterInputKey == 'by_category'){
        return false
      }else{
        return true
      }
      
    }
  }
}
export const createSlugByIdName = (inputString: string): string => {
return inputString.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-').trim()

}


// Helper function to compare 'params' objects
export const areParamsEqual = (params1: any, params2: any) => {
  // Implement your custom comparison logic here
  // For a simple shallow comparison, you can use JSON.stringify
  return JSON.stringify(params1) === JSON.stringify(params2);
};

export const triggerGA4Event = ( category: string ,
                          action: string ,
                          label : string,
                          value : any,) => {
  // Track a custom event
  ReactGA.event({
    category:  category,
    action: action,
    label: label,
    value: value, // A numeric value (e.g., price)
    nonInteraction: false, // Whether it affects bounce rate
    transport: 'beacon', // Transport method
  });
};

export  const refreshToken = async () =>  {
 return  await postData(AUTH_API_CONSTANTS.REFRESH_TOKEN, {}, APIEnv.NODE_DASHBOARD, 'get').then((res: any) => {
    if (res?.ResponseType === APIStatus.SUCCESS) {
      sessionStorage.setItem('refreshToken', res?.data?.token)
      return res?.data?.token
    }
  })
}

export  const getRedirectionUrlMapping = async () =>  {
  const UrlMapDatabase = sessionStorage['UrlMapDatabase'] || "[]";
  async function getUrlMapping() {
    return await getData(AUTH_API_CONSTANTS.REDIRECTION_URL_MAPPING, {}, null, APIEnv.NODE_BOOKSTORE).then((res: any) => {
      if (res?.ResponseType === APIStatus.SUCCESS) {
        sessionStorage.setItem('refreshToken', res?.data?.token)
        sessionStorage['UrlMapDatabase'] = JSON.stringify(res?.data || []);
        return res?.data || [];
      }
    }) 
  }
  if(!JSON.parse(UrlMapDatabase).length) {
    return getUrlMapping();
  } else {
    try {
      let data = JSON.parse(UrlMapDatabase);
      return data;
    } catch (error) {
      return getUrlMapping();
    }
  }
 }


export interface IFooterLinkModel {
  name: string,
  url: string,
  externalLink?: boolean,
  sameTab?: boolean,
  queryParams?: any,
  isHtml?: boolean,
}

export const getUserSiteTrackingData =(): any =>{
  let componentLocation = decodeURIComponent(location.href).replace(/'/g, "");
  const deviceData = useDeviceData(navigator.userAgent);
  const dataDevice = getSelectorsByUserAgent('deviceDetect');
  let deviceType =  dataDevice.isDesktop ? DeviceType.DESKTOP : dataDevice.isDesktop.isTablet ? DeviceType.TABLET : dataDevice.isDesktop.isMobile ? DeviceType.MOBILE : DeviceType.UNKNOWN  ;
  const obj = {
    [apiConstants.ipAddress]: getDataFromLocalStorage(LocalStorageDataModel.USER_IP),
    [apiConstants.userAgent]: navigator.userAgent,
    [apiConstants.device]: deviceData?.device?.model ? deviceData?.device?.model : DeviceType.UNKNOWN,
    [apiConstants.os]: deviceData?.os?.name,
    [apiConstants.osVersion]: deviceData?.os?.version,
    [apiConstants.browser]: deviceData?.browser?.name,
    [apiConstants.browserVersion]: deviceData?.browser?.version,
    [apiConstants.deviceType]: deviceType,
    [apiConstants.componentURL]: componentLocation 
  };
  return obj;
}

export function scrolltoTophandler() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const loginWithReturnUrl = (url:string) => {
  window.open(`${urlPrefix + "/" + AppRouteURL.LOGIN + '?returnUrl=/' +  AppRouteURL.BASE_URL + url}`, "_self");
}

export const login = () => {
  let referrer = '';
  setDataOnLocalStorage(LocalStorageDataModel.INTERNAL_REFRERRER_URL, referrer);
    setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, window.location.href);
}
export const getIdForPriceRange = (value:string):string => {
  if (value) {
    const priceId: any = value?.split("-").slice(0, 2).join("-");
    if (priceId?.length > 0) {
      return priceId;
    }
  }
  return "";
}
export const getFilterKeyByApiKey = (apiKey: any) => {
  for (const key in FilterMapping) {
    if (FilterMapping[key].apiKey === apiKey) {
      return key;
    }
  }
  return ''; // Return null if no match is found
};
export const getFilterApiKey = (key: any) => {
      return FilterMapping[key].apiKey;
  // return ''; // Return null if no match is found
};

export const encodeSharableArray = (array: any[])=> {
  let str = array?.join(sharableBookStoreUrlSeparator);
  str = encodeURIComponent(str);
  return str;
}

export const decodeSharableArray = (str: string) =>{
  str = decodeURIComponent(str);
  let arr = str.split(sharableBookStoreUrlSeparator);
  return arr;
}

export const createCanonicalURL = (url: string) => {
  if (url) {
    let ele: any = document.querySelector(`link[rel='canonical']`) || null;
    if (ele) {
      let href = ele.href;
      if (href.localeCompare(url) == 0) {
        return;
      }
    }
    let link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    document.head.appendChild(link);
    link.setAttribute('href', url);
  }
}

export const pushUtmSourceInformation = (description: string, deleteCampaignAfterPush: boolean = false, recordType = 'GENERAL', data: any = {}, actionType = 'GENERAL'): void => {
  let campaignName = getDataFromLocalStorage('activeCampaign');
  const { pathname, search } = window.location;
  if (!campaignName) {
    return
  }
  let userIp = getDataFromLocalStorage(LocalStorageDataModel.USER_IP);
  let campaignUrl = getDataFromLocalStorage('activeCampaignUrl');
  let payload = {
    IpAddress: userIp,
    Action: description,
    CampaignName: campaignName,
    UtmDetails: JSON.parse(getDataFromLocalStorage(campaignName)),
    ComponentUrl: pathname + search,
    CampaignUrl: campaignUrl,
    uuid: getDataFromLocalStorage('utm_campaign_uuid'),
    platform: 'taxmann',
    recordType: recordType, // GENERAL, CRM,
    actionType: actionType, // GENERAL, FREE_TRIAL, REQUEST_CALLBACK,
    data: data, // can be as per IUTMDataProps keys,
    UserEmail: getDataFromLocalStorage(LocalStorageDataModel.EMAIL),
    UserName: getDataFromLocalStorage(LocalStorageDataModel.USER_NAME),
  }
  let isLoggedIn = checkIsUserLoggedIn();
  let url = isLoggedIn ? 'tracking/userActionTracking' : 'tracking/userActionTracking?verifyToken=NO';
  postData(url,
    payload,
    APIEnv.MARKETING,).then((res) => {
      if (res && res.success == true) {
        if (deleteCampaignAfterPush) {
          removeDataFromLocalStorage('activeCampaign');
          removeDataFromLocalStorage(campaignName);
          removeDataFromLocalStorage('utm_campaign_uuid')
        }
      }
    }).catch((err) => { })
}

export const getYoutubeVideoId = (url:string) => {
  const embedMatch = url.match(/\/embed\/([^?]+)/);
  const standardMatch = !embedMatch && url.match(/[?&]v=([^?]+)/);

  const match:any= embedMatch || standardMatch;
  return match && match[1];
};

export const getNameFromEnd = (stringWithIdInFront: string): string => {
  if (stringWithIdInFront) {
    const a: any[] = stringWithIdInFront?.split("-");
    if (a?.length > 0) {
      a.shift();
      let name: string = a.join('-');
      return name;
    }
  }
  return "";
};

export function Env() {
  console.log(env())
  return environments[env()]
}

function env() {
  if (window.location.hostname.indexOf('localhost') !== -1) {
      return 'development';
  } else if (window.location.hostname.indexOf('qa') !== -1) {
      return 'development';
  } else if (window.location.hostname.indexOf('dev') !== -1) {
      return 'development';
  } else if (window.location.hostname.indexOf('preprod') !== -1) {
      return 'staging';
  } else if (window.location.hostname.indexOf('taxpractice') !== -1) {
      return 'development';
  } else if (window.location.hostname.indexOf('mctl') !== -1) {
      return 'development';
  } else if (window.location.hostname.indexOf('stag') !== -1) {
      return 'staging';
  } else if (window.location.hostname.indexOf('lsupport') !== -1) {
      return 'development';
  } else if (window.location.hostname.indexOf('vflipbook') !== -1) {
      return 'development';
  } else {
    return 'production';
  }
}

export const handlePrice = (price: number): string =>
  price?.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");

export const getMachineId = async () => {
  if (getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW)) {
      return Promise.resolve(true);
  } else {	
  removeDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
  return await postData(AUTH_API_CONSTANTS.GET_MACHINE_ID, {}, APIEnv.REDIS, 'get').then((res: any) => {
      if (res?.ResponseType === APIStatus.SUCCESS) {
          if (res.Data) {
              let decryptedID:any= decrypt(res.Data);
              if(decryptedID){
                  decryptedID = JSON.parse(decryptedID)
                  if(decryptedID && decryptedID.machineId){
                   setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW, decryptedID.machineId);
              // kept old machine id too , as it can affect the otther apps like bookstore and students
                    setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, decryptedID.machineId);
                    return Promise.resolve(true);
                  }else{
                      return Promise.resolve(false);	
                  }
              }else{
                  return Promise.resolve(false);	
              }
          }
      }
  })
}
}

export const  decrypt = (ciphertext: string) =>{
  let key = Env().REACT_APP_SCCRET_KEY_MACID;
  const secretKey = (key).replace(/KEYGEN/g, btoa('KEYGEN'));
  var bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}
