import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {HeaderTypes} from "@Src/types/api-response-interface";
import {Currency} from "@Constants/constants";
import { getDataFromLocalStorage } from '@Src/utils/globalUtilities';

const initialState = {
    loading: false,
    user: null,
    loginError: "",
    showProfileSidebar: false,
    showWebSidebar: true,
    showFooter: true,
    manageWebSidebar: true,
    activeHeader: HeaderTypes.MAIN_HEADER,
    currency: getDataFromLocalStorage("currency")
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        showSpinner: (state) => {
            state.loading = true;
        },
        hideSpinner: (state) => {
            state.loading = false;
        },
        manageProfileSidebar: (state, action) => {
            state.showProfileSidebar = action.payload;
        },
        setActiveHeader: (state, action) => {
            state.activeHeader = action.payload;
        },
        manageWebSidebar: (state, action) => {
            state.manageWebSidebar = action.payload;
        },
        showWebSidebar: (state, action) => {
            state.showWebSidebar = action.payload;
        },
        showFooter: (state, action) => {
            state.showFooter = action.payload;
        },
        setAppCurrency: (state, action) => {
            state.currency = action.payload;
        }
    }
});

export const { showSpinner, hideSpinner,manageWebSidebar, manageProfileSidebar, setActiveHeader, showWebSidebar, showFooter, setAppCurrency } = globalSlice.actions;
export default globalSlice.reducer;
