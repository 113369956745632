import {postData} from "@Services/bookstore/global.service";
import {APIEnv, APIResponse, APIStatus} from "@Services/bookstore/baseInterfaces";
import {PRODUCT_API_CONSTANTS} from "@Constants/api-constants/product/product.constants";
import {SEO_MAPPING} from "@Constants/constants";

export class SeoHandlerClass {


    url: string = ''

    constructor(url: string) {

        this.url = url;
    }

    getSeoDetails = async (pathName: string) => {

        let params: any = {};
        let API_VALUE: any = '';

        if (pathName?.includes(SEO_MAPPING.WRITE_REVIEW.URL_CONTAINS) && pathName  != SEO_MAPPING.WRITE_REVIEW.URL_CONTAINS) {
            params = this.getRegexValue(pathName, SEO_MAPPING.WRITE_REVIEW.PATTERN, SEO_MAPPING.WRITE_REVIEW.PARAMS)
            API_VALUE = SEO_MAPPING.WRITE_REVIEW.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.REVIEW_VIEW_ALL.URL_CONTAINS) && pathName  != SEO_MAPPING.REVIEW_VIEW_ALL.URL_CONTAINS) {
            params = this.getRegexValue(pathName, SEO_MAPPING.REVIEW_VIEW_ALL.PATTERN, SEO_MAPPING.REVIEW_VIEW_ALL.PARAMS)
            API_VALUE = SEO_MAPPING.REVIEW_VIEW_ALL.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.AUTHOR_DETAILS.URL_CONTAINS) && pathName != SEO_MAPPING.AUTHOR_DETAILS.URL_CONTAINS) {
            params = this.getRegexValue(pathName, SEO_MAPPING.AUTHOR_DETAILS.PATTERN, SEO_MAPPING.AUTHOR_DETAILS.PARAMS)
            API_VALUE = SEO_MAPPING.AUTHOR_DETAILS.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.AUTHOR_LIST.URL_CONTAINS) && pathName  == SEO_MAPPING.AUTHOR_LIST.URL_CONTAINS) {
            params = this.getRegexValue(pathName, SEO_MAPPING.AUTHOR_LIST.PATTERN, SEO_MAPPING.AUTHOR_LIST.PARAMS)
            API_VALUE = SEO_MAPPING.AUTHOR_LIST.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.HOME_PAGE.URL_CONTAINS) && pathName  == SEO_MAPPING.HOME_PAGE.URL_CONTAINS) {
            params = this.getRegexValue(pathName, SEO_MAPPING.HOME_PAGE.PATTERN, SEO_MAPPING.HOME_PAGE.PARAMS)
            API_VALUE = SEO_MAPPING.HOME_PAGE.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.PRODUCT_DETAILS.URL_CONTAINS) && pathName  != SEO_MAPPING.PRODUCT_DETAILS.URL_CONTAINS) {
            params = this.getRegexValue(pathName, SEO_MAPPING.PRODUCT_DETAILS.PATTERN, SEO_MAPPING.PRODUCT_DETAILS.PARAMS)
            API_VALUE = SEO_MAPPING.PRODUCT_DETAILS.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.CATEGORY_LIST_PROFESSIONAL.URL_CONTAINS) && pathName  != SEO_MAPPING.CATEGORY_LIST_PROFESSIONAL.URL_CONTAINS && pathName?.includes(SEO_MAPPING.CATEGORY_LIST_PROFESSIONAL.CATEGORY_LABEL)) {
            params = this.getRegexValue(pathName, SEO_MAPPING.CATEGORY_LIST_PROFESSIONAL.PATTERN, SEO_MAPPING.CATEGORY_LIST_PROFESSIONAL.PARAMS)
            API_VALUE = SEO_MAPPING.CATEGORY_LIST_PROFESSIONAL.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.CATEGORY_LIST_BARE_ACTS.URL_CONTAINS) && pathName  != SEO_MAPPING.CATEGORY_LIST_BARE_ACTS.URL_CONTAINS && pathName?.includes(SEO_MAPPING.CATEGORY_LIST_BARE_ACTS.CATEGORY_LABEL)) {
            params = this.getRegexValue(pathName, SEO_MAPPING.CATEGORY_LIST_BARE_ACTS.PATTERN, SEO_MAPPING.CATEGORY_LIST_BARE_ACTS.PARAMS)
            API_VALUE = SEO_MAPPING.CATEGORY_LIST_BARE_ACTS.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.JOURNAL_HOME.URL_CONTAINS) && pathName   == SEO_MAPPING.JOURNAL_HOME.URL_CONTAINS ) {
            params = this.getRegexValue(pathName, SEO_MAPPING.JOURNAL_HOME.PATTERN, SEO_MAPPING.JOURNAL_HOME.PARAMS)
            API_VALUE = SEO_MAPPING.JOURNAL_HOME.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.ACADEMIC_BOOKS.URL_CONTAINS) && pathName  != SEO_MAPPING.ACADEMIC_BOOKS.URL_CONTAINS && pathName?.includes(SEO_MAPPING.ACADEMIC_BOOKS.CATEGORY_LABEL)) {
            params = this.getRegexValue(pathName, SEO_MAPPING.ACADEMIC_BOOKS.PATTERN, SEO_MAPPING.ACADEMIC_BOOKS.PARAMS)
            API_VALUE = SEO_MAPPING.ACADEMIC_BOOKS.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.CERTIFICATION_COURSE.URL_CONTAINS) && pathName  != SEO_MAPPING.CERTIFICATION_COURSE.URL_CONTAINS && pathName?.includes(SEO_MAPPING.CERTIFICATION_COURSE.CATEGORY_LABEL)) {
            params = this.getRegexValue(pathName, SEO_MAPPING.CERTIFICATION_COURSE.PATTERN, SEO_MAPPING.CERTIFICATION_COURSE.PARAMS)
            API_VALUE = SEO_MAPPING.CERTIFICATION_COURSE.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.BOOKMANN_INDIA.URL_CONTAINS) && pathName  != SEO_MAPPING.BOOKMANN_INDIA.URL_CONTAINS && pathName?.includes(SEO_MAPPING.BOOKMANN_INDIA.CATEGORY_LABEL)) {
            params = this.getRegexValue(pathName, SEO_MAPPING.CATEGORY_LIST_BARE_ACTS.PATTERN, SEO_MAPPING.BOOKMANN_INDIA.PARAMS)
            API_VALUE = SEO_MAPPING.BOOKMANN_INDIA.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.COLLECTION_LIST.URL_CONTAINS) && pathName  != SEO_MAPPING.COLLECTION_LIST.URL_CONTAINS) {
            params = this.getRegexValue(pathName, SEO_MAPPING.COLLECTION_LIST.PATTERN, SEO_MAPPING.COLLECTION_LIST.PARAMS)
            API_VALUE = SEO_MAPPING.COLLECTION_LIST.API_VALUE
        } else if (pathName?.includes(SEO_MAPPING.SAMPLE_JOURNAL_ISSUE_LIST.URL_CONTAINS) && pathName  != SEO_MAPPING.SAMPLE_JOURNAL_ISSUE_LIST.URL_CONTAINS) {
            params = this.getRegexValue(pathName, SEO_MAPPING.SAMPLE_JOURNAL_ISSUE_LIST.PATTERN, SEO_MAPPING.SAMPLE_JOURNAL_ISSUE_LIST.PARAMS)
            API_VALUE = SEO_MAPPING.SAMPLE_JOURNAL_ISSUE_LIST.API_VALUE
        }



        return await postData(PRODUCT_API_CONSTANTS.SEO_DETAILS, {
            "API_VALUE": API_VALUE,
            "params": params
        }, APIEnv.NODE_BOOKSTORE, 'post').then((response: APIResponse | undefined) => {
            if (response?.ResponseType === APIStatus.SUCCESS) {
                return response?.data;
            }
        }).catch(err => {
            return null;
        })
    }


    getRegexValue(inputStr: string, inputRegex: any, params: any) {
        const inputString = inputStr;
        let result: any = {};
        const match = inputString.match(inputRegex);
        if (match) {
            for (let i = 0; i < params.length; i++) {
                const var1 = parseInt(match[i + 1], 10);
                result[params[i]] = var1;
            }

        }

        return result;
    }
}