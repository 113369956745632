import { AUTH_API_CONSTANTS } from "@Constants/api-constants/auth/auth.constants";
import { APIEnv, APIResponse } from "../bookstore/baseInterfaces";
import { getData } from "../bookstore/global.service";
import { USER_API_CONSTANTS } from "@Constants/api-constants/user/user.constant";

export const getStateProfessionInterest = (
  urlParam: any,
  successCB: (res: any | APIResponse) => void,
  errorCB?: (err: any) => void
) => {
  getData(
    AUTH_API_CONSTANTS.GET_STATE_PROFESSION_INTEREST,
    {},
    urlParam,
    APIEnv.NODE_DASHBOARD
  )
    .then(successCB)
    .catch((error) => {
      if (errorCB) errorCB(error?.response?.data);
    });
};

export const getUserInfo = (
  successCB: (res: any | APIResponse) => void,
  errorCB?: (err: any) => void
) => {
  getData(
    USER_API_CONSTANTS.USER_PERSONAL_INFO,
    {},
    null,
    APIEnv.NODE_DASHBOARD
  )
    .then(successCB)
    .catch((error) => {
      if (errorCB) errorCB(error?.response?.data);
    });
};
