import React from 'react';
import "./profileSidebar.scss"
import {useNavigate} from "react-router-dom";

export const ProfileSidebar = () => {
    const navigate = useNavigate()
    return (
        <>

        </>

    )
}

