import React, {useEffect, useState} from 'react'
import {Route, Routes, useLocation, useSearchParams} from 'react-router-dom';
import './App.scss';
import ReactGA from 'react-ga';
import {useAppDispatch, useAppSelector} from '@App/hooks/hooks';
import Loader from '@Components/loader/Loader';
import {Toaster, useToaster} from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '@Components/layout/Layout';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import {RouteChangeSubscription} from "@Src/utils/subscriptions/route-change/route-change";
import {BOOKSTORE_ROUTE_CHANGES} from '@Constants/dev-route-redirect.constant';
import {PROD_BOOKSTORE_ROUTE_CHANGES} from '@Constants/prod-route-redirect.constant';
import {
	checkIsUserLoggedIn,
	Env,
	getDataFromLocalStorage, pushUtmSourceInformation, refreshToken, getRedirectionUrlMapping,
	setDataOnLocalStorage,
	setDataOnLocalStorageWithExpiry,
	getMachineId
} from "@Src/utils/globalUtilities";
import {useGet} from './app/hooks/useGet/useGet';
import {AUTH_API_CONSTANTS} from '@Constants/api-constants/auth/auth.constants';
import {APIEnv, APIStatus} from './services/bookstore/baseInterfaces';
import {LocalStorageDataModel} from '@Constants/api-constants/local-storage-data-model';
import {
	ENDMILE_API_EVENT_LIST,
	ENDMILE_API_FIELD_ID_MAPPING_LIST
} from '@Constants/api-constants/endMileAPI.event.list';
import {
	createEndmileAPITrackObject,
	netCoreEventRegister,
	netCoreEventTrack,
	postEndmileAPIEventTrack
} from './services/bookstore/eventTrack.service';
import {NETCORE_API_FIELD_ID_MAPPING_LIST, NETCORE_EVENT_LIST} from '@Constants/api-constants/netcore.eventList';
import { AUTHOR_ROUTE, Currency, monthsArrayConst } from '@Constants/constants';
import { DBConfig } from "./DBConfig";
import { initDB } from "react-indexed-db-hook";
import { ScrollToTop } from '@Components/scroll-to-top/scroll-to-top';
import { LiveStream } from '@Components/live-stream/live-stream';
import { v4 as uuidv4 } from 'uuid';
import { getUserInfo } from './services/effects/otherEffects';
initDB(DBConfig);


const App: React.FC = () => {
	const [redirectionFlag, setRedirectionFlag] = useState(false);
	const dispatch = useAppDispatch();
	const [isAskedForLogin, setIsAskedForLogin] = useState(false);
	const isSpinnerVisible = useAppSelector(state => state.global);
	const [shouldCallIpAddressApi, setShouldCallIpAddressApi] = useState<any>(null);
	const [refreshUserToken, setRefreshUserToken] = useState<string>(sessionStorage.getItem('refreshToken') || '');
	const { data: clientIpAddress } = useGet(
		AUTH_API_CONSTANTS.CLIEND_IP_NODE,
		{},
		null,
		APIEnv.NODE_DASHBOARD,
		shouldCallIpAddressApi
	);

	const location = useLocation();
	const params:any = new URLSearchParams(location.search);
	const { pathname} = window.location;

	useEffect(() => {
		storeUtmInfo();
		(async () => {
			// const token: any = await refreshToken()
			// setRefreshUserToken(token)
			if (params.get('utm_medium') && params.get('utm_medium')?.toLowerCase().includes('cid')) {
				let utm_medium_arr: any = params.get('utm_medium')?.toLowerCase().split('cid');
				let cid = utm_medium_arr[1];
				setDataOnLocalStorageWithExpiry(LocalStorageDataModel.ENDMILE_EVENTS_CID_NUMBER, cid, 24 * 60 * 60 * 100);
			}
		})();
	}, [params]);

	function storeUtmInfo() {
		if (params.get('utm_campaign')) {
			let utm_campaign_uuid = getDataFromLocalStorage('utm_campaign_uuid') || '';
			if (!utm_campaign_uuid) {
				utm_campaign_uuid = uuidv4();
				setDataOnLocalStorage('utm_campaign_uuid', utm_campaign_uuid);
			}
			let utmInfo:any = {};
			params.forEach((value:any, key:any) => {
				if (key.includes('utm_')) {
				  utmInfo[key] = value;
				}
			  });
			setDataOnLocalStorage(params.get('utm_campaign'), JSON.stringify(utmInfo));
			setDataOnLocalStorage('activeCampaign', params.get('utm_campaign'));
			setDataOnLocalStorage('activeCampaignUrl', pathname+location.search);
			pushUtmSourceInformation('User Initial visit by ' + params.get('utm_campaign'))
		}
	

}

	useEffect(() => {
		(async () => {
			// const token: any = await refreshToken()
			// setRefreshUserToken(token)

			// Logic to execute when the route changes
			let UrlMapDatabase: any = await getRedirectionUrlMapping();//PROD_BOOKSTORE_ROUTE_CHANGES;
			// if (Env().REACT_APP_NODE_ENV === 'development') {
			// 	UrlMapDatabase = BOOKSTORE_ROUTE_CHANGES;
			// }
			let searchPath = window.location.pathname;
			if(window.location.search.length > 0) {
				searchPath += window.location.search;
			}
			let isOld = UrlMapDatabase.filter((data: any) => data.Source === searchPath);
			
			if (isOld.length) {
				window.location.href = isOld[0]['Destination'];
			} else {
				setRedirectionFlag(true);
			}
			//End Mile event track code starts
			// if (checkIsUserLoggedIn()) {
			// 	let cidNumber: any = getDataFromLocalStorage(LocalStorageDataModel.ENDMILE_EVENTS_CID_NUMBER) || '';
			// 	if (cidNumber && cidNumber.length) {
			// 		cidNumber = JSON.parse(cidNumber);
			// 	}
			// 	let trackData = [{
			// 		"fieldid": ENDMILE_API_FIELD_ID_MAPPING_LIST.FIRST_NAME_LAST_NAME,
			// 		"value": getDataFromLocalStorage(LocalStorageDataModel.USER_NAME)
			// 	},
			// 		{"fieldid": ENDMILE_API_FIELD_ID_MAPPING_LIST.PHONE_NUMBER, "value": ''},
			// 		{"fieldid": ENDMILE_API_FIELD_ID_MAPPING_LIST.LOCATION, "value": ''},
			// 		{"fieldid": ENDMILE_API_FIELD_ID_MAPPING_LIST.PAGE_URL, "value": window.location.href},
			// 		{"fieldid": ENDMILE_API_FIELD_ID_MAPPING_LIST.PAGE_CATEGORY, "value": "internal"},
			// 		{"fieldid": ENDMILE_API_FIELD_ID_MAPPING_LIST.PAGE_TITLE, "value": "internal"},
			// 		{
			// 			"fieldid": ENDMILE_API_FIELD_ID_MAPPING_LIST.CID,
			// 			"value": cidNumber && cidNumber.length ? cidNumber?.value : cidNumber
			// 		}];
			// 	let payload = createEndmileAPITrackObject(ENDMILE_API_EVENT_LIST.PAGE_VISITED, trackData);
			// 	postEndmileAPIEventTrack(payload);
			// }
			//	End Mile event track code ends
			// netcore event track starts
			let netCorePayload = {
				[NETCORE_API_FIELD_ID_MAPPING_LIST.PAGE_URL]: window.location.href,
				[NETCORE_API_FIELD_ID_MAPPING_LIST.PAGE_CATEGORY]: 'bookstore' // related to bookstore only
			}
			netCoreEventTrack(NETCORE_EVENT_LIST.PAGE_BROWSE, netCorePayload)
			// netcore event track ends
		})();
	}, []);


	useEffect(() => {
		(async () => {
			if( await getMachineId() ){
			const token: any = await refreshToken()
			setRefreshUserToken(token)
			}
 			const tempIP = getDataFromLocalStorage("userIp");
			// console.log(window.location.host, 'ssss')
			// if (window.location.host?.includes('localhost') && !token) {
			// 	if (confirm("Do you want to use login scenario.")) {
			// 		let email: any = prompt('Enter Email');
			// 		let userName: any = prompt('Enter name');
			// 		let inputToken = prompt('Enter Token');
			// 		if (inputToken) {
			// 			setDataOnLocalStorage('email', email)
			// 			setDataOnLocalStorage('userName', userName)
			// 			setDataOnLocalStorage('TaxmannAuthorization', inputToken)
			// 		}
			// 	}
			// }
			if (!tempIP) {
				setShouldCallIpAddressApi(true);
			} else {
				setShouldCallIpAddressApi(null);
			}
			netCoreEventRegister();
			initializeGA4EventTrack();
			if(getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN) && !getDataFromLocalStorage(LocalStorageDataModel.EXTRA_NETCORE_PAYLOAD)) {
				getUserInfo((response: any) => {
					// if (response.ResponseType === APIStatus.SUCCESS) {
					//     setUserProfile(response.data);
					// }
					if (response.ResponseType == APIStatus.SUCCESS) {
						const personalInfo = response.data;
						let netCorePayload:any = {
							"name" : personalInfo?.lastName ? personalInfo?.firstName + ' ' + personalInfo?.lastName : ( personalInfo?.firstName ||  '' ),
							"designation" : personalInfo?.designation?.designationName || "",
							"profession" : personalInfo.profession?.profession_name || personalInfo.professionNameOther || (personalInfo?.studentInterest?.course?.id ? 'students' : '') ,
							"email" : personalInfo?.email || "",
							"city" : personalInfo?.city || "",
							"state" : personalInfo?.state?.state_name || ""
						}
						if(personalInfo.interestId && personalInfo.interestId.length){
							let interestArr = personalInfo.interestId.map((interest:any)=> interest.name);
							let interestedNames = interestArr.join(' | ');
							netCorePayload['areaOfSpecialization'] = interestedNames; 
						}
						if(personalInfo.studentInterest && personalInfo.studentInterest.course && personalInfo.studentInterest.course.id){
							netCorePayload['courseName'] = personalInfo.studentInterest.course.name;
							if(personalInfo.studentInterest.groupLevel && personalInfo.studentInterest.groupLevel.id){
								netCorePayload['courseLevel'] = personalInfo.studentInterest.groupLevel.name;
							}
							if(personalInfo.studentInterest.attemptMonth && personalInfo.studentInterest.attemptMonth.id){
								let monthId = personalInfo.studentInterest.attemptMonth.name;
								let monthData:any = monthsArrayConst.find((month:any)=> month.id ==monthId );
								netCorePayload['monthAttempt'] = monthData.name;
							}
							if(personalInfo.studentInterest.attemptYear && personalInfo.studentInterest.attemptYear.id){
								netCorePayload['yearAttempt'] = personalInfo.studentInterest.attemptYear.name;
							}
						}
	
						setDataOnLocalStorage(LocalStorageDataModel.EXTRA_NETCORE_PAYLOAD , JSON.stringify(netCorePayload));
						// setUserProfile(response.Data);
					}
				});
			}		
		})();

		!getDataFromLocalStorage("currency") && setDataOnLocalStorage("currency" , Currency.INR)


	}, [])

	useEffect(() => {
		if (clientIpAddress?.ResponseType == APIStatus.SUCCESS) {
			setDataOnLocalStorage("userIp", clientIpAddress?.data?.ipAddress);
		}
	}, [clientIpAddress]);

	function initializeGA4EventTrack() {

		ReactGA.initialize('GTM-PF8RQLP');
	}


	return (
		<div>

			{
				( refreshUserToken && redirectionFlag ) ?
					<>
					<RouteChangeSubscription />
					<Routes>
						<Route path='/*' element={<Layout />} />
					</Routes>

					{isSpinnerVisible.loading ? <Loader /> : null}

					<div>

						<div>
							<Toaster
								containerStyle={{
									bottom:100,
								  }}
								position="top-center"
								reverseOrder={false}
							/>
						</div>
						<ScrollToTop />
						<LiveStream />
					</div>
 					</> : ''
			}
		</div>
	);
}

export default App;
