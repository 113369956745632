import React, {useEffect, useState} from 'react'
import {useGet} from "@App/hooks/useGet/useGet";
import {HOME_API_CONSTANTS} from "@Constants/api-constants/home/home.constant";
import {APIEnv} from "@Services/bookstore/baseInterfaces";
import './new-release.scss'
import {useAppDispatch, useAppSelector} from "@App/hooks/hooks";
import {manageWebSidebar, showFooter, showWebSidebar} from "@App/reducers/global/globalSlice";
import {Currency} from "@Constants/constants";
import {AppRouteURL} from "@Src/routes/app-route-urls";

export const NewReleasesPage = () => {
    const {data: newReleaseProducts} = useGet(HOME_API_CONSTANTS.Latest_RELEASES + '/all', {}, null, APIEnv.NODE_BOOKSTORE, true);
    const dispatch = useAppDispatch();
    const globalStore = useAppSelector(state => state.global)

    const [formattedData, setFormattedData] = useState<Array<any>>([]);

    useEffect(() => {
        dispatch(manageWebSidebar(false));
        dispatch(showWebSidebar(false));
        dispatch(showFooter(false));
    }, []);

    useEffect(() => {
        if (newReleaseProducts?.data?.length > 0) {
            setFormattedData(divideArrayIntoBlocks(newReleaseProducts?.data, 4));
        }
    }, [newReleaseProducts]);

    function divideArrayIntoBlocks(array: Array<any>, blockSize: number) {
        const result = [];

        for (let i = 0; i < array.length; i += blockSize) {
            result.push(array.slice(i, i + blockSize));
        }
        return result;
    }

    function getAllAuthorNames(authors: any) {
        return authors && authors?.length &&
            authors?.map((author: any, index: number) => {
                return (authors?.length > 1 && authors?.length - 1 !== index) ? author?.name + ", " : author?.name;
            })
    }

    return (
        <>

            <table style={{fontFamily: 'arial', width: '980px', margin: '0 auto'}} cellSpacing="0" cellPadding="0">
                <tr>
                    <td>
                        <h2 className="bookstore-heading">New Releases</h2>
                    </td>
                </tr>
                {
                    formattedData?.map((product: any) => {
                        return <>
                            <tr>
                                {
                                    product?.map((book: any) => {
                                        return <>
                                            <td>
                                                <table className="container new-releases" cellSpacing="0"
                                                       cellPadding="0">

                                                    <tr className="newreleases-main-table">
                                                        <td width="25%">
                                                            <table>
                                                                <tr>
                                                                    <td className="newreleases-card-sec  mob-mo1">
                                                                        <a target="_blank"
                                                                           href={'/bookstore'+AppRouteURL.PRODUCT_DETAIL + book?.editionId}
                                                                           className="card-image">

                                                                            <img style={{
                                                                                width: '180px',
                                                                                height: '262px',
                                                                                minWidth: '180px'
                                                                            }} height="262" width="180"
                                                                                 className="card-img-top"
                                                                                 src={book?.image}/>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="card-body">

                                                                        <ul className="book-details pt-2"
                                                                            style={{
                                                                                listStyleType: 'none',
                                                                                fontSize: '16px'
                                                                            }}>
                                                                            <li className="book-name">
                                                                                <a>{book?.editionName}</a></li>
                                                                            <li className="author">
                                                                                {
                                                                                    book?.authors?.length > 0 ?
                                                                                        <span>
                                                                                             <span data-pr-position="bottom"
                                                                                                   className={'highlight-1 ' + 'author-tooltip' + book?.editionId}> {getAllAuthorNames(book?.authors)}
                                                                                            </span>
                                                                                        </span> : ''
                                                                                }
                                                                            </li>
                                                                            <li className="d-flex justify-content-between align-items-center">
                                                                                <span className="price">
                                                                                    {
                                                                                        (book?.price && globalStore?.currency === Currency.INR) &&
                                                                                        <>
                                                                                            {
                                                                                                book?.price?.domesticPrice ?
                                                                                                    <span className='book-buy-price'> ₹{book?.price?.domesticPrice} </span> : ''
                                                                                            }

                                                                                        </>
                                                                                    }

                                                                                    {
                                                                                        (book?.price && globalStore?.currency === Currency.USD) &&
                                                                                        <>
                                                                                            {
                                                                                                book?.price?.internationalPrice ?
                                                                                                    <span className='book-buy-price'> ${book?.price?.internationalPrice} </span> : ''
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </span>
                                                                            </li>

                                                                        </ul>

                                                                    </td>
                                                                </tr>

                                                            </table>

                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </>
                                    })
                                }
                            </tr>
                        </>
                    })
                }
            </table>


        </>

    )
}

