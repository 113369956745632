export const LocalStorageDataModel = {
	NETCORE_EVENT_TRACK:'netcoreEventTrack',
	ENDMILE_EVENTS_CID_NUMBER:'endmileEventsCidNumber',
	EMAIL:'email',
    USER_NAME: "userName",
	USER_IP: 'userIp',
	PREVIOUS_URL: "PREVIOUS_URL",
	MACHINE_ID:'machineId',
	INTERNAL_REFRERRER_URL: 'internalReferrerUrl',
    AUTH_TOKEN: "TaxmannAuthorization",
	EXTRA_NETCORE_PAYLOAD:'extraNetCorePayload',
	MACHINE_ID_NEW:'machineId1',
}
