import { useEffect, useState } from "react";
import "./scroll-to-top.scss";
import { scrolltoTophandler } from "@Src/utils/globalUtilities";

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const listenToScroll = () => {
    let heightToShowFrom = 100;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll < heightToShowFrom) {
      // to limit setting state only the first time
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <>
      {isVisible ? (
        <div className="scroll-to-top" onClick={() => scrolltoTophandler()}>
          <svg
            width="52"
            height="56"
            viewBox="0 0 52 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_5390_68299)">
              <circle
                cx="26"
                cy="22"
                r="22"
                transform="rotate(-90 26 22)"
                fill="white"
              />
              <circle
                cx="26"
                cy="22"
                r="21.5"
                transform="rotate(-90 26 22)"
                stroke="#E6E6E8"
              />
            </g>
            <path
              d="M19 26L26 18.1765L33 26"
              stroke="#8B54FF"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <defs>
              <filter
                id="filter0_d_5390_68299"
                x="0"
                y="0"
                width="52"
                height="56"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_5390_68299"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_5390_68299"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
