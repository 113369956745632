/**
 * To maps properties of each possible filter
 * @description: Below constant maps each filter with its properties. We can directly access all possible filter and its
 * properties from a central place.
 */
export const FilterMapping:any = {
  by_category: {
    label: "Category",
    apiKey: "category",
    urlKey: "category",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 1,
  },
  by_subject: {
    label: "Subject",
    apiKey: "subject",
    urlKey: "subject",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 2,
  },
  by_legal: {
    label: "Legal",
    apiKey: "legal",
    urlKey: "legal",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 4,
  },
  by_bookAvailability: {
    label: "Availability",
    apiKey: "availability",
    urlKey: "availability",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 3,
  },
  by_publication: {
    label: "Year of Publication",
    apiKey: "publication",
    urlKey: "publication",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 6,
  },
  by_language: {
    label: "Language",
    apiKey: "language",
    urlKey: "language",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 5,
  },
  by_author: {
    label: "Author",
    apiKey: "authors",
    urlKey: "author",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 8,
  },
  by_format: {
    label: "Format",
    apiKey: "format",
    urlKey: "format",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 7,
  },
  by_course: {
    label: "Course",
    apiKey: "courses",
    urlKey: "course",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 8,
  },
  by_courseLevel: {
    label: "Course Level",
    apiKey: "courseLevel",
    urlKey: "courselevel",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 9,
  },
  by_courseLevelExamGroup: {
    label: "Course Level Exam Group",
    apiKey: "courseLevelExamGroup",
    urlKey: "courselevelexamgroup",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 10,
  },
  by_exam: {
    label: "Exam",
    apiKey: "exams",
    urlKey: "exam",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 11,
  },
  by_educationBoard: {
    label: "Education Board",
    apiKey: "educationBoard",
    urlKey: "educationboard",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 12,
  },
  by_institute: {
    label: "Institutes",
    apiKey: "institutes",
    urlKey: "institute",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 13,
  },
  by_grade: {
    label: "Grade",
    apiKey: "standards",
    urlKey: "standard",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 14,
  },
  by_publisher: {
    label: "Publisher",
    apiKey: "publisher",
    urlKey: "publisher",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 15,
  },
  by_collection: {
    label: "Collection",
    apiKey: "collections",
    urlKey: "collection",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 16,
  },
  by_parentJournalId: {
    label: "Journal",
    apiKey: "parentJournalId",
    urlKey: "parentjournal",
    defaultValue: "",
    valueLabel: "name",
    valueKey: "id",
    order: 17,
  },
  by_timePeriod: {
    label: "Time Period",
    apiKey: "timePeriod",
    urlKey: "timeperiod",
    defaultValue: "",
    valueLabel: "timePeriod",
    valueKey: "timePeriod",
    order: 18,
  },
  by_partNo: {
    label: "Part No",
    apiKey: "partNo",
    urlKey: "partno",
    defaultValue: "",
    valueLabel: "partNo",
    valueKey: "partNo",
    order: 19,
  },
  by_volumeNo: {
    label: "Volume No",
    apiKey: "volumeNo",
    urlKey: "volumeno",
    defaultValue: "",
    valueLabel: "volumeNo",
    valueKey: "volumeNo",
    order: 20,
  },
};

export const FormDataKeyType:any = {
  category: "string", 
  subject: "string",
  legal: "string",
  availability:"string",
  publication: "string",
  language: "string",
  authors: "string",
  format: "number",
  courses: "string",
  courseLevel: "string",
  courseLevelExamGroup: "string",
  exams: "string",
  educationBoard: "string",
  institutes: "string",
  standards: "string",
  publisher: "string",
  collections: "string",
  parentJournalId: "string",
  timePeriod: "string",
  partNo: "string",
  volumeNo: "string",
}
