import React from 'react'
import "./Loader.scss"
import Spinner from '@Src/assets/images/Spinner.gif'
import { Env } from '@Src/utils/globalUtilities';

const mindlerIcon = `${Env().REACT_APP_MINDLER_PRODUCT_IMAGES_URL}/profile-builder/icons/mindler.png`;

const Loader = () => {
    return (
        <>
            <div className="spinner">
                <div className="loadingio-spinner-spin-ox1zzdvclb"><div className="ldio-s1z3dkijgo">
                    <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
                </div></div>
            </div>

            {/* <div className="spinner">
                <div>
                    <img src={Spinner} alt="spinner" />
                </div>
            </div> */}
        </>

    )
}

export default Loader