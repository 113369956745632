import React, {lazy, useState} from "react";
import "./Layout.scss";
import {useAppSelector} from "@App/hooks/hooks";
import AppRoutes from "@Src/routes/route";
import {ProfileSidebar} from "@Features/sidebar/profile-sidebar/profileSidebar";
import { GlobalContext } from "@Src/contexts/GlobalContexts";


const HeaderIndex = lazy(() =>
    import('@Features/header')
        .then(({HeaderIndex}) => ({default: HeaderIndex})),
);
const CustomSidebar = lazy(() =>
    import('@Features/sidebar/sidebar')
        .then(({CustomSidebar}) => ({default: CustomSidebar})),
);
const Footer = lazy(() =>
    import('@Src/features/footer/footer')
        .then(({Footer}) => ({default: Footer})),
);


const Layout = () => {

    const globalSlice = useAppSelector(state => state.global);
    const [globalContext,setGlobalContext] = useState({
        interactedWithSideMenuOrSearch : false,
        isSearch : false
    });
    return (
        <>
            {
                globalSlice?.showProfileSidebar ?
                    <GlobalContext.Provider value = {{globalContext, setGlobalContext}}>
                        <div className="profile-layout">
                            <React.Suspense>
                                <HeaderIndex/>
                            </React.Suspense>
                            <div className='ui-contents-1 format-user-ui-1'>
                                <div className='user-ui-page-1'>
                                    <ProfileSidebar/>

                                    <div className='user-ui-contents-1'>
                                        <AppRoutes/>

                                    </div>
                                </div>
                                <Footer/>

                            </div>
                        </div>
                    </GlobalContext.Provider>
                    :
                    <GlobalContext.Provider value = {{globalContext, setGlobalContext}} >
                        <div className="layout">
                            <React.Suspense>
                                <HeaderIndex/>
                            </React.Suspense>
                            <div className="layout-container">
                                <React.Suspense>
                                    {

                                     <CustomSidebar/>
                                    }
                                </React.Suspense>
                                <div className={globalSlice?.showWebSidebar ? 'layout-contents-right' : (globalSlice?.manageWebSidebar ? 'layout-contents-right collapse-closed' : 'layout-contents')}>
                                    <AppRoutes/>
                                    <React.Suspense>
                                        {
                                            globalSlice?.showFooter && <Footer/>
                                        }
                                    </React.Suspense>
                                </div>
                            </div>
                                    
                        </div>
                    </GlobalContext.Provider>
            }
        </>
    );
}

export default Layout;
