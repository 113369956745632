import { Env } from "@Src/utils/globalUtilities";
import axios from "axios";
const unauthorizedCode = [401];

export const DotNetBaseService = axios.create({
  timeout: 60000,
  baseURL: Env().REACT_APP_DOT_NET_BASE_URL,
});

DotNetBaseService.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("TaxmannAuthorization");
      config.headers = {
          refreshtoken: sessionStorage.getItem('refreshToken') || ''
      }
      if (token) {
          config.headers = {
              ...config.headers,
              taxmannauthorization: token,

          };
    }
    return config;
    // config.headers = {
    //     		'authorization ': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdF9uYW1lIjoicHJhc2hhbnQiLCJsYXN0X25hbWUiOiJzaGFybWEiLCJlbWFpbCI6InByYXNoYW50QGdtYWlsLmNvbSIsInV1aWQiOjEsImlhdCI6MTY3OTAzNjQzOSwiZXhwIjoxNjgxNjI4NDM5fQ.YOE4q1Y0Y5r5lwk8f-90qRPDB1Ytw3isMAwao8h-xEs'
    //     	}
    // return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

DotNetBaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && unauthorizedCode.includes(response.status)) {
      // logoutUserSession()
    }
    return Promise.reject(error);
  }
);
