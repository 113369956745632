export const AppRouteURL = {
  LOGIN: "auth/login",
  LOGOUT: "auth/logout",
  PRODUCT_LIST_BOOKMANN_INDIA: "/listing/bookmann-india", //done
  Book_Demo_Pages: "/product/demo/uipages",
  PRODUCT_SEARCH_PAGE: "/product/search",
  VIRTUAL_BOOK_ISSUES: "/virtual/book/issues",
  RESEARCH_OFFLINE: "listing/research-offline/",
  COMPLIANCE: "listing/compliance/",
  JOURNAL_ISSSUE: "/listing/issue/",
  VIRTUAL_LIBRARY_JOURNAL_ISSUE: "/virtual-library-journalissue/",

  VIRTUAL_BOOK_LIST: "/virtual/book/list",
  VIRTUAL_BOOK_DETAIL: "/virtual/book/detail",
  VIRTUAL_JOURNAL_SAMPLE_ISSUES: "/requestsample/", //done
  CART: "/cart", //done
  CHECKOUT_INFORMATION: "/checkout/information", //done
  CHECKOUT_PAYMENT: "/checkout/payment", //done
  THANK_YOU_PURCHASE: "/thank-you", //done
  PURCHASE_EXPERIENCE_FEEDBACK: "/purchase-experience/feedback", //done
  PURCHASE_PRODUCT_FEEDBACK: "/purchase/feedback", //done
  PAYMENT_FAILED: "/payment/failed", //done
  PROFILE_INFORMATION: "/user/profile", //done
  LATEST_RELEASES: "/latestrelease", //done
  PROFILE_GSTIN: "/user/gstin", //done
  PROFILE_CHANGE_PASSWORD: "/user/change-password", //done
  PROFILE_MANAGE_INTERESTS: "/user/interests", //done
  PROFILE_MANAGE_SUBSCRIPTIONS: "/user/subscription", //done
  PROFILE_MANAGE_ORDERS: "/user/order", //done
  PROFILE_VIRTUAL_LIBRARY: "/user/library", //done
  PROFILE_WISHLIST: "/user/wishlist", //done
  PROFILE_WALLET: "/user/wallet", //done,
  PROFILE_LINKED_DEVICE:'/user/linked-devices',
  PROFILE_REFER_EARN: "/user/refer", //done
  PROFILE_REFER_AND_EARN: "/user/refer-and-earn", //done
  PROFILE_MANAGE_NEWSLETTER:'/user/my-newsletter',
  PROFILE_ADDRESS: "/user/address", //done
  COLLECTION_LIST: "/collection/list",
  COLLECTION_DETAIL: "/collection/detail",
  WRITE_REVIEW_SUCCESS: "/write-review-success", // done
  DIGITAL_FOOTPRINT: "/digital/footprint/", //done
  BASE_URL:'bookstore',



  /**
   * with seo below
   */
  HOME: "/",
  PRODUCT_DETAIL: "/product/",
  AUTHORS_LIST: "authors", // done
  PRODUCT_LIST: "/listing/", //done
  PRODUCT_LIST_JOURNAL: "/listing/journals", //done
  PRODUCT_LIST_COLLECTION: "collection/",
  WRITE_REVIEW: "/product/", // done
  ALL_REVIEWS: "/product/",
  PAGE_NOT_FOUND: "/404",
  
};
