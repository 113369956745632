import ApiService from "@Services/bookstore/ApiService";
import {APIEnv} from "@Services/bookstore/baseInterfaces";

export async function getData(endPoint: string, queryParams: object = {}, urlParam: any, APIEnvironment: APIEnv, customHeaders?: any) {
    return await ApiService.fetchData({
        url: urlParam ? endPoint + "/" + urlParam : endPoint,
        method: "get",
        params: queryParams,
        customHeaders: customHeaders
    }, APIEnvironment);
}
export async function postData(endPoint: string, data: any, APIEnvironment: APIEnv, method: string = 'post', customHeaders?: any) {

    return await ApiService.fetchData({
        url:  endPoint ,
        method: method,
        data: data,
        customHeaders: customHeaders
    }, APIEnvironment);
}

export async function postDataWithDebounce(endPoint: string, data: any, APIEnvironment: APIEnv, method: string = 'post', customHeaders?: any, debounceTime: number = 300) {
    return new Promise((resolve, reject) => {
        const debouncedFunction = debounce(async () => {
            try {
                const response = await ApiService.fetchData({
                    url: endPoint,
                    method: method,
                    data: data,
                    customHeaders: customHeaders
                }, APIEnvironment);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        }, debounceTime); // Adjust the debounce delay (300 milliseconds in this example)

        debouncedFunction(); // Call the debounced function
    });
}

export function debounce(func: any, delay: any) {
    let timeout: any = 0;
    return function (...args: any) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...args);
        }, delay);
    };
}