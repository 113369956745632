import { useEffect, useState } from "react";
import "primeicons/primeicons.css";
import "./live-stream.scss";
import { scrolltoTophandler } from "@Src/utils/globalUtilities";
import { APIEnv } from "@Src/services/bookstore/baseInterfaces";
import { getData } from "@Src/services/bookstore/global.service";
import { API_ROUTES } from "@Src/services/bookstore/api.constants";


export const LiveStream = () => {
  const [liveTvStatus, setLiveTvStatus] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [liveTvUrl, setLiveTvUrl] = useState<string>("");
  const [liveTvUrlSafe, setLiveTvUrlSafe] = useState<string>("");
  const [liveVideoTitle, setLiveVideoTitle] = useState<string>("");
  const [visibleUrlArr, setVisibleUrlArr] = useState([]);
  const [isVisibleToggle, setIsVisibleToggle] = useState(false);

  const toggleVisibility = () => {
    setIsVisibleToggle(!isVisibleToggle); // Toggle the value of isVisible
  };

  useEffect(()=>{
				if (!visibleUrlArr?.length) {
					getLiveVideoStreamDetails();
				} else {
					matchUrl(visibleUrlArr);
				}
  },[location.pathname])


	const getLiveVideoStreamDetails =()=> {
    getData( API_ROUTES.BUDGET_MARATHON_TV,
       {},
       null,
      APIEnv.DOT_NET).then(((res:any) => {
        if (res.Status === 'DATA_FOUND') {
          setLiveTvStatus(res?.Data?.status || 0);
          setLiveTvUrl(res?.Data?.streamurl || '');
          setVisibleUrlArr(res?.Data?.taxmannurllist || []);
          setLiveVideoTitle(res?.Data?.title || "");
          setLiveTvUrlSafe(res?.Data?.streamurl || '')
          matchUrl(res?.Data?.taxmannurllist || []);
        }
    })
  ).catch(err=> {
  })
	}

	const matchUrl = (urlArr:any) =>{
		if(location.pathname) {
      let path = location.pathname;
			let matchedUrlArr = urlArr.filter((url: string) => {
				// let matchUrl = url;
				if(url == '/advisory' || url == '/advisory/') {
					return (path === "/advisory") || (path === "/advisory/");
				} else if(url == '/academy' || url == '/academy/') {
					return (path === "/academy") || (path === "/academy/");
				} else {
					return path === url;
				}
			});
			if (matchedUrlArr?.length) {
				 setShowVideo(true);
			} else {
        setShowVideo(false);
			}
		}

	}

  return (
    <>
{liveTvStatus == 1 && showVideo ?
  <div className="page-videos">
	<div  className="l-video-header" onClick={toggleVisibility}>
		<span> { liveVideoTitle }</span>
		<span className="d-flex justify-content-center align-items-center">
			<i className="pi pi-chevron-down" aria-hidden="true"></i>
		</span>
	</div>
{!isVisibleToggle && (
	<div id="embvideos" className="collapse l-videos show">
		<iframe
			width="300"
			height="315"
			src={liveTvUrlSafe} 
      frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		></iframe>
	</div>
)}
</div>
:''}
    </>
  );
};
