export const ENDMILE_API_EVENT_LIST = {
    PAGE_VISITED: 'Page Visited',
    PRODUCT_VIEWED : 'Product viewed',
    ADD_TO_CART : 'Product_clicked_add_to card',
    BUY_NOW : 'product_buynow_clicked',
    ADD_TO_WISHLIST : 'product_wishlist_clicked',
    PROCEED_TO_CHECKOUT : 'cart_proceedtocheckout_clicked',
    PROCEED_TO_PAYMENT : 'checkout_proceedtopay_clicked',
    PAYMENT_INITIATED : 'payment_initiated',
    PAYMENT_SUCCESSFUL : 'payment_successfull',
    PAYMENT_FAILED : 'payment_failed',
    ORDER_CONFIRMED : 'order_confirmed',
    SIGN_UP_INITIATE : 'user_signup_initiated',
    SIGN_UP_CONFIRMED : 'user_signup_confirmed',
    SIGNED_IN : 'user_signedin',
    VIRTUAL_TRIAL:'virtualbooktrial'
}

export const ENDMILE_API_FIELD_ID_MAPPING_LIST = {
    FIRST_NAME_LAST_NAME: '8',
    PRRODUCT_SERVICES:'25',
    ACTIVATON_DATE: '26',
    EXPIRY_DATE:'27',
    PHONE_NUMBER: '36',
    LOCATION:'53',
    CITY:'53',
    DESGNATION:'56',
    PROFESSION:'60',
    CID:'61',
    PRODUCT_SUB_CATEGORY:'70',
    PRODUCT_NAME:'73',
    AREA_OF_SPECIALIZATION:'75',
    STATE:'76',
    PAGE_URL:'171',
    PAGE_CATEGORY:'172',
    PAGE_TITLE:'173',
    PRODUCT_ID: "210",
   PRODUCT_TYPE:"175",
   CART_SIZE:'176',
   ORDER_VALUE:'177',
   PAYMENT_MODE:'178',
   PROMO_CODE:'179',
   TRANSACTION_ID:'180',
   TRANSACTION_AMOUNT:'181',
   TRANSACTION_DATE:'182',
   TRANSACTION_TIME:'183',
   PAYMENT_METHOD:'184',
   ORDER_ID:'185',
   ORDER_AMOUNT:'186',
   ORDER_DATE:'187',
   ORDER_TIME:'188',
   COURSE:'189',
   COURSE_LEVEL:'190',
   YEAR_OF_ATTEMPT:'191',
   MONTH_OF_ATTEMPT:'192',
   COURSE_SUBJECT:'193',
   PRODUCT_CATEGORY: '214',
   EXAM: '217',
   INSTITUTES: '218'
}

export const ENDMILE_API_CONSTANT = {
    CID:'cid',
    INTERNAL:'internal',
    OID:'OID',
    TID:'TID'
}