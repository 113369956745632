export interface APIResponse {
    ResponseType: string
    StatusMsg: string
    data: any
    success: boolean
}

export const APIStatus  = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    DATA_NOT_FOUND: 'DATA_NOT_FOUND',
    TRIAL_ALREADY_TAKEN: 'TRIAL_ALREADY_TAKEN'
}

export enum APIEnv {
    NODE_BOOKSTORE,
    NODE_DASHBOARD,
    NODE_STUDENT,
    DOT_NET,
    ENDMILE,
    REDIS,
    MARKETING
}