export const AUTH_API_CONSTANTS = {
  GET_CLIENT_IP: "/login/getclientipbydomain",
  LOGIN: "/auth/login",
  LOGOUT : "/auth/logout",
  COUNTRY_CODE_LIST: "/userAccount/countryCodes",
  GET_STATE_PROFESSION_INTEREST: "auth/registration/getStateProfessionInterest",
  CLIEND_IP_NODE: "/clientIp",
  USER_SITE_TRACKING:'userSiteTracking',
  REFRESH_TOKEN: "refreshToken/web",
  REDIRECTION_URL_MAPPING: "listingRedirection",
  GET_MACHINE_ID: 'machineId'
};
