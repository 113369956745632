import { useEffect } from "react";
// import useIndexedDB from "@App/hooks/useIndexDb/useIndexDb";
import { useIndexedDB } from 'react-indexed-db-hook';
import { DBKey } from "@Src/DBConfig";
import { checkIsUserLoggedIn, getUserSiteTrackingData } from "@Src/utils/globalUtilities";
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';
import { APIEnv, APIStatus } from "@Src/services/bookstore/baseInterfaces";
import { AUTH_API_CONSTANTS } from "@Constants/api-constants/auth/auth.constants";
import { postData } from "@Src/services/bookstore/global.service";

export function IndexDbEvents({ pathname }: any) {
    const databaseName = 'MyDatabase';
    const storeName = 'MyStore';
    // const db: any = useIndexedDB(databaseName, storeName);
    const { update, add, getAll, deleteRecord, getByIndex,clear } = useIndexedDB(DBKey.StoreName);

    useEffect(() => {
        pushUrlToIndexDb();
    }, [pathname])

    useEffect(() => {
            saveUserDeviceVisitedRouteInfo();
    }, [])

    const pushUrlToIndexDb=()=> {
		const data = getUserSiteTrackingData();
        getAll().then((result: any) => {
         //   const allUserTrackingData = result;
    //const isSameData = allUserTrackingData?.filter((i: any)=> i.componentURL === data.componentURL)
            let dataToPush = {
                [DBKey.SchemaName1]: data,
              }
              add(dataToPush).then((event: any) => {
              });      
		})
	}

    const saveUserDeviceVisitedRouteInfo = () => {
        window.setInterval(() => {
            window.setTimeout(() => {
                getAll().then((result: any) => {
                    const allUserTrackingObservable = result;
                    const data:any = { TrackingList: [] }
                    allUserTrackingObservable.forEach((allUserTrackingData: any) => {
                            if (allUserTrackingData && allUserTrackingData?.data) {
                              if (allUserTrackingData.data.ipAddress) {
                                    data.TrackingList.push(allUserTrackingData.data);
                              }
                            }

                    })
                    if (data.TrackingList.length > 0) {
                        // maintain uniqueness of user site tracking data
                        const mapUniqueByKey = new Map(
                            data.TrackingList.map(((item:any) =>
                            [item["componentURL"], item])
                            ));
                            const mapUniqueByKeyValue = mapUniqueByKey.values();
                            const arrayUniqueByKey:any = [...mapUniqueByKeyValue];
                            let payload:any = {TrackingList: arrayUniqueByKey};
                            // directly using global utitlity function here to check login, instead of login variable due to need of getting login info run time as app component start runs only single time, and doesn't update login varibale in real time
                            if(checkIsUserLoggedIn()){}else{
                                payload['verifyToken'] = 'NO';
                            }
                            // post site tracking
                            postData(
                                AUTH_API_CONSTANTS.USER_SITE_TRACKING,
                                payload,
                                APIEnv.MARKETING,).then((res:any) => {
                                    if (res.ResponseType === APIStatus.SUCCESS) {
                                        clear().then(() => {
                                        });
                                    }
                            }).catch((err:any) => { })
                        }
                        })
            }, 200);
        }, 15000)
    }

    // useEffect(() => {
    //     if (db && pathname) {
    //         // Use the IndexedDB instance (db) to perform operations
    //         const transaction = db?.transaction(storeName, 'readwrite');
    //         const store = transaction?.objectStore(storeName);

    //         // Example: Add data to the store
    //         const dataToAdd = { id: 1, name: 'Example Data' };
    //         const addRequest = store.add(dataToAdd);
    //         addRequest.onsuccess = (event: any) => {
    //             console.log('Data added to IndexedDB');
    //         };

    //         addRequest.onerror = (event: any) => {
    //             console.error('Error adding data to IndexedDB', event?.target?.error);
    //         };
    //     }
    // }, [db, pathname]);

    return (<>
    </>)
}