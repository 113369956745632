import { LocalStorageDataModel } from "@Constants/api-constants/local-storage-data-model";
import { Env, getDataFromLocalStorage, setDataOnLocalStorage } from "@Src/utils/globalUtilities";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
const unauthorizedCode = [401];

export const RedisBaseService = axios.create({
  timeout: 60000,
  baseURL: Env().REACT_APP_REDIS_URL,
});

RedisBaseService.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("TaxmannAuthorization");
    let machineId:any = getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW);
    if(machineId){}else{
      // generate machibe id and save ib local storage
      //     let id:string = uuidv4();
      //     id = id.replace(/-/g,"");
      // machineId = id;
      // setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, id);
    }
    config.headers = {
      [LocalStorageDataModel.MACHINE_ID]: machineId
    };

    if (token) {
      config.headers = {
        taxmannauthorization: token,
      };
    }
    return config;
    // config.headers = {
    //     		'authorization ': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdF9uYW1lIjoicHJhc2hhbnQiLCJsYXN0X25hbWUiOiJzaGFybWEiLCJlbWFpbCI6InByYXNoYW50QGdtYWlsLmNvbSIsInV1aWQiOjEsImlhdCI6MTY3OTAzNjQzOSwiZXhwIjoxNjgxNjI4NDM5fQ.YOE4q1Y0Y5r5lwk8f-90qRPDB1Ytw3isMAwao8h-xEs'
    //     	}
    // return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

RedisBaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && unauthorizedCode.includes(response.status)) {
      // logoutUserSession()
    }
    return Promise.reject(error);
  }
);
