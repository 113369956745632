export const DBKey = {
    'DBName':'routeTrackingDB',
    'StoreName': 'user_device_route_tracking',
    'SchemaName1': 'data',
  }; 

export const DBConfig = {
    name: DBKey.DBName,
    version: 5,
    objectStoresMeta: [
      {
        store: DBKey.StoreName,
        storeConfig: { keyPath: "id", autoIncrement: true },
        storeSchema: [
          { name: DBKey.SchemaName1, keypath: DBKey.SchemaName1, options: { unique: true } },
        ],
      },
    ],
  };

