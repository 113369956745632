export const HOME_API_CONSTANTS = {
    Latest_RELEASES: "/home/list/latestReleases",
    TOP_BANNER_ADVT: "/advertisements",
    FEATURED_COLLECTION: "/home/list/featuredCollections",
    // UPCOMING: "/home/list/upcoming",
    BEST_SELLERS: "/home/list/bestSellers",
    Pre_Order: "/home/list/preOrder",
    TRENDING: "/home/list/trending",
    // TOP_RATED: "/home/list/topRatedBooks",
    BUNDLES: "/home/list/bundles",
    Featured:"/home/list/featured",
    Best_In_Combos:"/home/list/bestInCombos",
    Budget_Publication:"/home/list/budgetPublication",
    Expert_Recommendations:"/home/list/expertRecommendations",
    RESEARCH_NEWS_UPDATES: "research/getResearchNewsUpdates/all",
    WEBINARS: "research/getResearchNewsUpdates/all",
    ENDMILE_EVENT_TRACK:'eventemileProcess',
    ZOOM_WEBINAR:'webinar/zoom_getwebinarlist',
    TAX_AUDIT:"/home/list/taxAudit"
}