import { LocalStorageDataModel } from "@Constants/api-constants/local-storage-data-model";
import { Env, getDataFromLocalStorage, setDataOnLocalStorage } from "@Src/utils/globalUtilities";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
const unauthorizedCode = [401];

export const NodEndmileBaseService = axios.create({
  timeout: 60000,
  baseURL: Env().REACT_APP_ENDMILE_API_SERVER
});

NodEndmileBaseService.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("TaxmannAuthorization");
      let machineId:any = getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW);
      if(machineId){}else{
        // generate machibe id and save ib local storage
        //     let id:string = uuidv4();
        //     id = id.replace(/-/g,"");
        // machineId = id;
        // setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, id);
      }
      config.headers = {
        refreshtoken: sessionStorage.getItem('refreshToken') || '',
        [LocalStorageDataModel.MACHINE_ID]: machineId
      };

      if (token) {
          config.headers = {
              ...config.headers,
              taxmannauthorization: token,

          };

      if (config?.customHeaders) {
          config.headers = {...config?.headers, ... config?.customHeaders}
      }
    }
    return config;
    // config.headers = {
    //     		'authorization ': 'eyJhbGciOiJIUzI1NiJ9.eyJpZCI6IjMiLCJlbWFpbCI6IjMiLCJ0aW1lU3RhbXAiOjE2ODE5MTE0MjI0MjQsIm5iZiI6MTY4MTkxMTQyMiwiZXhwIjoxNjg0NTAzNDIyLCJpYXQiOjE2ODE5MTE0MjIsImlzcyI6Ijg3M2IxYWRkLTcwMzQtNDYyNy04NzgzLTA1M2Q0N2JiODE0OCIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NjQwNjQvIn0.G2sMfBodWz4-QSm2Ps5Gavms84MvGh4JRoDWxCJ6A1I'
    //     	}
    // return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

NodEndmileBaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && unauthorizedCode.includes(response.status)) {
      // logoutUserSession()
    }
    return Promise.reject(error);
  }
);
