import { LocalStorageDataModel } from "@Constants/api-constants/local-storage-data-model";
import { Env, getDataFromLocalStorage } from "@Src/utils/globalUtilities";
import { APIEnv } from "./baseInterfaces";
import { HOME_API_CONSTANTS } from "@Constants/api-constants/home/home.constant";
import { postData } from "./global.service";
import { ENDMILE_API_FIELD_ID_MAPPING_LIST } from "@Constants/api-constants/endMileAPI.event.list";
import { NETCORE_API_FIELD_ID_MAPPING_LIST } from "@Constants/api-constants/netcore.eventList";
declare var smartech: Function;

function extractNetCorePayload() {
	let extraNetCorePayloadFromLocalStorage = getDataFromLocalStorage(LocalStorageDataModel.EXTRA_NETCORE_PAYLOAD) || {};
	if(typeof(extraNetCorePayloadFromLocalStorage) == 'string') {
		try {
			extraNetCorePayloadFromLocalStorage = JSON.parse(extraNetCorePayloadFromLocalStorage);
		} catch (error) {
			extraNetCorePayloadFromLocalStorage = {};
		}
	}
	return extraNetCorePayloadFromLocalStorage;
}

    export  function createEndmileAPITrackObject(event:string,fields:any){
        let email = getDataFromLocalStorage(LocalStorageDataModel.EMAIL) || '';
		let cidNumber: any = getDataFromLocalStorage(LocalStorageDataModel.ENDMILE_EVENTS_CID_NUMBER) || '';
		if (cidNumber && cidNumber.length) {
			cidNumber = JSON.parse(cidNumber);
		}	
		let dataWithCidNumber = [
		{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.CID,"value":cidNumber && cidNumber.value ? cidNumber?.value : ''}
		]
		;
		let data:any = extractNetCorePayload();
		fields = [
			{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.FIRST_NAME_LAST_NAME, "value":data?.name || ""},
			{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.DESGNATION, "value":data?.designation || ""},
			{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.PROFESSION, "value":data?.profession || ""},
			...fields, ...dataWithCidNumber
		]
		let notFoundMap: any = {};
		for(let i=0;i<fields.length;i++) {
			if(!notFoundMap[fields[i].fieldid]) {
				notFoundMap[fields[i].fieldid] = fields[i].fieldid;
			}
		}
		if(!notFoundMap[ENDMILE_API_FIELD_ID_MAPPING_LIST.CITY]) {
			fields = [
				...fields,
				{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.CITY, "value":data?.city || ""}
			]
		}
		if(!notFoundMap[ENDMILE_API_FIELD_ID_MAPPING_LIST.STATE]) {
			fields = [
				...fields,
				{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.STATE, "value":data?.state || ""}
			]
		}
			    let interestedData = [];
		if(data.areaOfSpecialization && data.areaOfSpecialization.length){
			interestedData.push(
				{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.AREA_OF_SPECIALIZATION, "value":data?.areaOfSpecialization}
			)
		}
		if(data.courseName && data.courseName.length){
			interestedData.push(
				{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.COURSE, "value":data?.courseName}
			)
		}
		if(data.courseLevel && data.courseLevel.length){
			interestedData.push(
				{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.COURSE_LEVEL, "value":data?.courseLevel}
			)
		}
		if(data.monthAttempt && data.monthAttempt.length){
			interestedData.push(
				{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.MONTH_OF_ATTEMPT, "value":data?.monthAttempt}
			)
		}
		if(data.yearAttempt && data.yearAttempt.length){
			interestedData.push(
				{"fieldid":ENDMILE_API_FIELD_ID_MAPPING_LIST.YEAR_OF_ATTEMPT, "value":data?.yearAttempt}
			)
		}
      fields = [...fields, ...interestedData ];
		let payload =
		{
		"requestmethod":"AddSubscriberToList",
		"details":
		{"emailaddress":email,"event_name":event,"mailinglist":"1","format":"html","confirmed":"yes","customfields":
		{"items":fields
	}}}
	   return payload;
	}

     export function postEndmileAPIEventTrack(data:any) {
    //    if (window.location.hostname.indexOf('www.taxmann.com') !== -1) {            
        postData(  
            HOME_API_CONSTANTS.ENDMILE_EVENT_TRACK,
            data,        
            APIEnv.ENDMILE,).then((res) => {
            }).catch((err) => { })        
        // }
    }

	export function netCoreEventRegister() {
		// netcore regster key starts
		let netCoreRegisterKey = Env().REACT_APP_NETCORE_REGISTER_KEY;
		smartech('register', netCoreRegisterKey);
				// netcore regster key ends
	}

	export function netCoreEventTrack(eventType:string, payload:object, Email?:string) {
		let email = getDataFromLocalStorage(LocalStorageDataModel.EMAIL) || Email;
		let campaignName = getDataFromLocalStorage('activeCampaign');
		let payloadWithUTM = payload;
		if(campaignName && campaignName.length){
			let utmData = JSON.parse(getDataFromLocalStorage(campaignName));
			if(utmData && Object.keys(utmData).length){
				utmData ={
					[NETCORE_API_FIELD_ID_MAPPING_LIST.UTM_SOURCE] : utmData.utm_source,
					[NETCORE_API_FIELD_ID_MAPPING_LIST.UTM_MEDIUM] : utmData.utm_medium,
					[NETCORE_API_FIELD_ID_MAPPING_LIST.UTM_CAMPAIGN] : utmData.utm_campaign,
				}
		payloadWithUTM = {...utmData, ...payloadWithUTM};
			}
		}else{
			let utmData ={
				[NETCORE_API_FIELD_ID_MAPPING_LIST.UTM_SOURCE] :'Bookstore Website'
			}
			payloadWithUTM = {...utmData, ...payloadWithUTM};
		}
		let extraNetCorePayloadFromLocalStorage = extractNetCorePayload();
		payloadWithUTM = {...payloadWithUTM, ...extraNetCorePayloadFromLocalStorage};
		console.log("payloadWithUTM", payloadWithUTM)
		console.log("payloadWithUTM", payloadWithUTM)
		smartech('identify', email);
		smartech('dispatch', eventType, payloadWithUTM);
	}
