import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    fetchCartCount: false,
    cartItemIndexChanged: null,
    fetchCartItems: false,
    fetchPhysicalSaveLaterItems: null,
    fetchVirtualSaveLaterItems: null,
    fetchAddToSaveLater: null,
    fetchDeleteFromSaveLater: null,
    fetchDeleteItemFromCart: null,
    patchSaveLaterItemFromCart: null,
    deleteItemFromCart: null,
    studentdeleteItemFromCart: null,
    studentcartitems:0,
    cartitemsvalue:0
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setFetchCartTotalItem: (state, action) => {
            state.fetchCartCount = action.payload;
        },
        setCartItemIndexChanged: (state, action) => {
            state.cartItemIndexChanged = action.payload;
        },
        setFetchCartItems: (state, action) => {
            state.fetchCartItems = action.payload;
        },
        setFetchPhysicalSaveLaterItems: (state, action) => {
            state.fetchPhysicalSaveLaterItems = action.payload;
        },
        setFetchVirtualSaveLaterItems: (state, action) => {
            state.fetchVirtualSaveLaterItems = action.payload;
        },
        setFetchAddToSaveLater: (state, action) => {
            state.fetchAddToSaveLater = action.payload;
        },
        setFetchDeleteFromSaveLater: (state, action) => {
            state.fetchDeleteFromSaveLater = action.payload;
        },
        setFetchDeleteItemFromCart: (state, action) => {
            state.fetchDeleteItemFromCart = action.payload;
        },
        setPatchSaveLaterItemFromCart: (state, action) => {
            state.patchSaveLaterItemFromCart = action.payload;
        },
        setDeleteItemFromCart: (state, action) => {
            state.deleteItemFromCart = action.payload;
        },
        setStudentDeleteItemFromCart: (state, action) => {
            state.studentdeleteItemFromCart = action.payload;
        },
        setstudentcartitems: (state, action) => {
            state.studentcartitems = action.payload;
        },
        setcartitemsvalue: (state, action) => {
            state.cartitemsvalue = action.payload;
        }
    }
});

export const {
    setFetchCartTotalItem,
    setFetchCartItems,
    setFetchPhysicalSaveLaterItems,
    setFetchVirtualSaveLaterItems,
    setCartItemIndexChanged,
    setDeleteItemFromCart,
    setStudentDeleteItemFromCart,
    setPatchSaveLaterItemFromCart,
    setstudentcartitems,
    setcartitemsvalue
} = cartSlice.actions;
export default cartSlice.reducer;
