export const PRODUCT_API_CONSTANTS = {
  PRODUCT_DETAIL: "product/productDetails",
  PRODUCT_OTHER_ACTION_DETAIL: "product/productUserInformation",
  PRODUCT_BOOK_KEY_ACTIVATION: "product/accessBookByActivationKey",
  PRODUCT_JOURNAL_KEY_ACTIVATION: "product/accessJournalByActivationKey",
  PRODUCT_VIRTUAL_BOOK_FREE_TRIAL: "product/virtualBookFreetrial",
  PRODUCT_VIRTUAL_JOURNAL_FREE_TRIAL: "product/virtualJournalFreetrial",
  PRODUCT_VIRTUAL_JOURNAL_ISSUE_LIST: "product/getJournalIssueList",
  PRODUCT_READ_NOW: "product/readNow",
  PRODUCT_JOURNAL_SAMPLE_ISSUE: "product/getJournalSampleIssueList",
  PRODUCT_JOURNAL_SAMPLE_ISSUE_REQUEST_FORM: "product/submitSampleRequest",
  TOGGLE_PRODUCT_WISHLIST: "product/toggleWishlist",
  RATING_LIST: "product/reviewRatingList",
  PRODUCT_DETAIL_BY_FORMAT: "product/productFormatEdition",
  PRODUCT_INDEX_LIST: "/list/productIndexList",
  GET_SERVER_DATE_TIME: "product/serverTime",
  PRODUCT_JOURNAL_INDEX_LIST: "list/productJournalIndexList",
  PRODUCT_JOURNAL_ISSUE_LIST: "list/productJournalIssueList",
  PRODUCT_NOTIFY_USER :"product/notifyUser",
  PRODUCT_SEARCH_SUGGESTION: 'list/getSuggestion',

  SEO_DETAILS: "seoDetails",
  COUNTRY_STATE_LIST: "/userAccount/getCountryStates",
  STATE_CITY_BY_PINCODE_LIST: "/userAccount/getStateCityByPINCode/",
	GET_FAQS: "support/faqs",
  MANAGE_URL: "product-redirect",

};

export const BOOKSTORE_CATEGORY_NAME:any = {
	'direct-tax-laws': 'Direct Tax Laws'
  }
export const Meta_API_CONSTANTS = {
  META_DETAIL : "metaDetails"
}
