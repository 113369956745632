import {AppRouteURL} from "@Src/routes/app-route-urls";

export const VALIDATION_REGEX_PATTERN = {
  mobileNumber: /^[1-9]{1}[0-9]{9}$/,
  emailPattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  mobile: /^[1-9]{1}[0-9]*$/,
  alphabet: /^[a-zA-Z ]*$/,
  linkedin_profile_url:
    /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
  url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  floatNumbers: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
  validMarks: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/,
  names: /^[a-zA-Z .]*$/,
};

export enum Currency {
  INR= "INR",
  USD="USD",
}

export enum ProductAttachmentTypes {
  UPLOAD_BASE_IMAGE= 1,
  UPLOAD_THUMBNAIL_IMAGE,
  UPLOAD_BOOK_CONTENT,
  UPLOAD_BOOK_PREFACE,
  UPLOAD_BOOK_SAMPLE_CHAPTER,
  UPLOAD_BOOK_VIDEO_LINK,
  UPLOAD_BOOK_PRESENTATION,
}

export enum ProductCategoryENUM {
  BOOK = "BOOK",
  JOURNAL = "JOURNAL",
  DVD = "DVD",
  ENEWSLETTER = "ENEWS",
  ADIT = "ADIT",
  RESEARCH = "RESEARCH",
  PRACTICE = "PRACTICE",
  EXAMS = "EXAM",
  BUNDLE = "BUNDLE",
  ISSUE = "ISSUE",
}
export enum ProductFormatTypeENUM {
  "VIRTUAL" = "VIRTUAL",
  "HARD" = "HARD",
  "POCKET" = "POCKET",
}

export enum ProductBadgeEnum {
  PREORDER = "PREORDER",
  BESTSELLER = "BESTSELLER",
  NEWRELEASE = "NEWRELEASE",
  POPULAR = "POPULAR",
  TRENDING = "TRENDING",
}

export enum SortByEnum {
  RELEASE_DATE_ASCENDING = "dlh",
  RELEASE_DATE_DECENDING = "dhl",
  PRICE_LOW_TO_HIGH = "plh",
  PRICE_HIGH_TO_LOW = "phl",
  RELEVANCE = "rank",
  CUSTOM="custom"
}

export const sortByOptions = [
  { title: "Relevance", value: SortByEnum.RELEVANCE },
  {
    title: "Release Date - Descending",
    value: SortByEnum.RELEASE_DATE_DECENDING,
  },
  {
    title: "Release Date - Ascending",
    value: SortByEnum.RELEASE_DATE_ASCENDING,
  },
  // { title: "Price---Low to High", value: SortByEnum.PRICE_LOW_TO_HIGH },
  // { title: "Price---High to Low", value: SortByEnum.PRICE_HIGH_TO_LOW },
];

export const apiConstants = {
  email: "email",
  password: "password",
  ipAddress: "ipAddress",
  userAgent: "userAgent",
  device: "device",
  os: "os",
  osVersion: "osVersion",
  browser: "browser",
  browserVersion: "browserVersion",
  deviceType: "deviceType",
  domainname: "domainname",
  countrycode: "countrycode",
  name: "name",
  mobile: "mobile",
  profession: "profession",
  professionOther: "professionOther",
  state: "state",
  productEditionId: "productEditionId",
  planKey: "planKey",
  componentURL:'componentURL'
};

export const urlPrefix = "/gp";
export const returnURL = "returnURL";

export interface CheckoutInformation {
  cartSource: string;
  billingId: string | number;
  shippingId: string | number;
  checkoutCart: string;
  siteSource?: string;
  currency?: string;
  type?: string; // platform type from where product is bought
  promoCode?: string;
}

export enum HeightAdjustElementType {
  HTML_ELEMENT = 1,
  HTML_ID,
  HTML_CLASS,
}

export enum CART_PRODUCT_TYPE {
  RESEARCH = "RESEARCH",
  BOOKSTORE = "BOOKSTORE",
  PRACTICE = "PRACTICE",
}

export const allowedCheckoutDataUrls = ['/cart', '/checkout/information', '/checkout/payment', '/payment/failed', '/thank-you/']


export const SEO_MAPPING  = {
   HOME_PAGE: {
     API_VALUE: 'HOME_PAGE',
     PATTERN: /(\d+)-/,
     PARAMS: [],
     URL_CONTAINS: '/'
   },
  PRODUCT_DETAILS: {
    API_VALUE: 'PRODUCT_DETAILS',
    PATTERN: /(\d+)-/,
    PARAMS: ['editionId'],
    URL_CONTAINS: '/product/'
  },
  CATEGORY_LIST_PROFESSIONAL: {
    API_VALUE: 'CATEGORY_LIST_PROFESSIONAL',
    PATTERN: /(\d+)-(\d+)/,
    PARAMS: [],
    URL_CONTAINS: '/listing/',
    CATEGORY_LABEL: 'professional-books/'
  },
  CATEGORY_LIST_BARE_ACTS: {
    API_VALUE: 'CATEGORY_LIST_BARE_ACTS',
    PATTERN: /(\d+)-(\d+)/,
    PARAMS: [],
    URL_CONTAINS: '/listing/',
    CATEGORY_LABEL: 'bare-acts/'
  },
  JOURNAL_HOME: {
    API_VALUE: 'JOURNAL_HOME',
    PATTERN: /(\d+)-(\d+)/,
    PARAMS: [],
    URL_CONTAINS: '/listing/journals',
  },
  ACADEMIC_BOOKS: {
    API_VALUE: 'ACADEMIC_BOOKS',
    PATTERN: /(\d+)-(\d+)/,
    PARAMS: [],
    URL_CONTAINS: '/listing/',
    CATEGORY_LABEL: 'academic-books/'
  },
  CERTIFICATION_COURSE: {
    API_VALUE: 'CERTIFICATION_COURSE',
    PATTERN: /(\d+)-(\d+)/,
    PARAMS: [],
    URL_CONTAINS: '/listing/',
    CATEGORY_LABEL: 'certification-course/'
  },
  BOOKMANN_INDIA: {
    API_VALUE: 'BOOKMANN_INDIA',
    PATTERN: /(\d+)-(\d+)/,
    PARAMS: [],
    URL_CONTAINS: '/listing/',
    CATEGORY_LABEL: 'bookmann-india/'
  },
  COLLECTION_LIST: {
    API_VALUE: 'COLLECTION_LIST',
    PATTERN: /(\d+)-(\d+)/,
    PARAMS: [],
    URL_CONTAINS: '/collection',
  },
  AUTHOR_DETAILS: {
    API_VALUE: 'AUTHOR_DETAILS',
    PATTERN: /(\d+)-/,
    PARAMS: ['Id'],
    URL_CONTAINS: '/authors/'
  },
  AUTHOR_LIST: {
    API_VALUE: 'AUTHOR_LIST',
    PATTERN: null,
    PARAMS: [],
    URL_CONTAINS: '/authors/'
  },
  WRITE_REVIEW: {
    API_VALUE: 'WRITE_REVIEW',
    PATTERN: null,
    PARAMS: ['editionId'],
    URL_CONTAINS: '/write-a-review/'
  },
  REVIEW_VIEW_ALL: {
    API_VALUE: 'REVIEW_VIEW_ALL',
    PATTERN: null,
    PARAMS: ['editionId'],
    URL_CONTAINS: '/all-reviews/'
  },
  SAMPLE_JOURNAL_ISSUE_LIST: {
    API_VALUE: 'SAMPLE_JOURNAL_ISSUE_LIST',
    PATTERN: /(\d+)-/,
    PARAMS: ['editionId'],
    URL_CONTAINS: '/requestsample/'
  },
}

export const ProductMetaInformation:any = {
  publisher:{
      title: 'Publisher',
      icon: `<svg width="32" height="32"
   viewBox="0 0 32 32" fill="none"
   xmlns="http://www.w3.org/2000/svg">
   <path
       d="M5.67993 14.6933V21.3199C5.67993 23.7466 5.67993 23.7466 7.97326 25.2933L14.2799 28.9333C15.2266 29.4799 16.7733 29.4799 17.7199 28.9333L24.0266 25.2933C26.3199 23.7466 26.3199 23.7466 26.3199 21.3199V14.6933C26.3199 12.2666 26.3199 12.2666 24.0266 10.7199L17.7199 7.07992C16.7733 6.53326 15.2266 6.53326 14.2799 7.07992L7.97326 10.7199C5.67993 12.2666 5.67993 12.2666 5.67993 14.6933Z"
       stroke="#111C42" strokeWidth="1.5" strokeLinecap="round"
       strokeLinejoin="round" />
   <path
       d="M23.3334 10.1737V6.66699C23.3334 4.00033 22.0001 2.66699 19.3334 2.66699H12.6667C10.0001 2.66699 8.66675 4.00033 8.66675 6.66699V10.0803"
       stroke="#111C42" strokeWidth="1.5" strokeLinecap="round"
       strokeLinejoin="round" />
   <path
       d="M16.8401 14.6533L17.6001 15.8399C17.7201 16.0266 17.9868 16.2133 18.1868 16.2666L19.5468 16.6133C20.3868 16.8266 20.6134 17.5466 20.0668 18.2133L19.1734 19.2933C19.0401 19.4666 18.9334 19.7733 18.9468 19.9866L19.0268 21.3866C19.0801 22.2533 18.4668 22.6933 17.6668 22.3733L16.3601 21.8533C16.1601 21.7733 15.8268 21.7733 15.6268 21.8533L14.3201 22.3733C13.5201 22.6933 12.9068 22.2399 12.9601 21.3866L13.0401 19.9866C13.0534 19.7733 12.9468 19.4533 12.8134 19.2933L11.9201 18.2133C11.3734 17.5466 11.6001 16.8266 12.4401 16.6133L13.8001 16.2666C14.0134 16.2133 14.2801 16.0133 14.3868 15.8399L15.1468 14.6533C15.6268 13.9333 16.3734 13.9333 16.8401 14.6533Z"
       stroke="#F88822" strokeWidth="1.5" strokeLinecap="round"
       strokeLinejoin="round" />
</svg>`,
      dataKey: 'publisher',
      applicable:true
  },
  format:{
    title: 'Book Format',
    icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.375 29.5C24.6181 29.5 25.625 28.4931 25.625 27.25V4.75C25.625 3.50688 24.6181 2.5 23.375 2.5H9.875C8.63188 2.5 7.625 3.50688 7.625 4.75V27.25C7.625 28.4931 8.63188 29.5 9.875 29.5H23.375ZM8.75 7.5625H24.5V23.875H8.75V7.5625ZM9.875 3.625H23.375C23.9937 3.625 24.5 4.13125 24.5 4.75V6.4375H8.75V4.75C8.75 4.13125 9.25625 3.625 9.875 3.625ZM8.75 27.25V25H24.5V27.25C24.5 27.8687 23.9937 28.375 23.375 28.375H9.875C9.25625 28.375 8.75 27.8687 8.75 27.25Z" fill="#111C42"/>
    <path d="M22.4412 11.3534C20.5119 10.6502 18.5544 10.6334 16.625 11.2915C14.6956 10.6334 12.7381 10.6502 10.8087 11.3534C10.5837 11.4321 10.4375 11.6459 10.4375 11.8821V20.0665C10.4375 20.2521 10.5275 20.4209 10.6794 20.5277C10.8313 20.6346 11.0225 20.6571 11.1969 20.5952C12.935 19.9652 14.6956 19.9652 16.4394 20.5952C16.445 20.5952 16.4506 20.5952 16.4562 20.6009C16.5069 20.6177 16.5519 20.6234 16.6081 20.629C16.6137 20.629 16.625 20.6346 16.6306 20.6346C16.6362 20.6346 16.6475 20.629 16.6531 20.629C16.6925 20.629 16.7262 20.6234 16.7656 20.6121C16.7769 20.6065 16.7937 20.6065 16.805 20.6009C16.8106 20.6009 16.8162 20.6009 16.8219 20.5952C18.56 19.9652 20.3206 19.9652 22.0644 20.5952C22.1262 20.6177 22.1937 20.629 22.2556 20.629C22.3681 20.629 22.4806 20.5952 22.5763 20.5277C22.7281 20.4209 22.8181 20.2521 22.8181 20.0665V11.8821C22.8125 11.6459 22.6662 11.4377 22.4412 11.3534ZM11.5625 19.2959V12.2871C13.0587 11.8202 14.5662 11.8202 16.0625 12.2871V19.2959C15.3144 19.0934 14.5606 18.9921 13.8125 18.9921C13.0644 18.9921 12.3106 19.0934 11.5625 19.2959ZM21.6875 19.2959C20.9394 19.0934 20.1856 18.9921 19.4375 18.9921C18.6894 18.9921 17.9356 19.0934 17.1875 19.2959V12.2871C18.6837 11.8202 20.1912 11.8202 21.6875 12.2871V19.2959Z" fill="#F88822"/>
    </svg>
    `,
    dataKey: 'format',
    applicable:true
},
  publicationDate:{
      title: 'Month & Year of Publication',
      icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.791 2.66699V6.66699" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.459 2.66699V6.66699" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.79102 12.1201H27.4577" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.125 11.3337V22.667C28.125 26.667 26.125 29.3337 21.4583 29.3337H10.7917C6.125 29.3337 4.125 26.667 4.125 22.667V11.3337C4.125 7.33366 6.125 4.66699 10.7917 4.66699H21.4583C26.125 4.66699 28.125 7.33366 28.125 11.3337Z" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.0519 18.2663H21.0639" stroke="#F88822" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.0519 22.2663H21.0639" stroke="#F88822" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.1183 18.2663H16.1303" stroke="#F88822" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.1183 22.2663H16.1303" stroke="#F88822" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.1847 18.2663H11.1967" stroke="#F88822" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.1847 22.2663H11.1967" stroke="#F88822" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>      
      `,
      dataKey: 'publicationDate',
      Publicationdate:true,
      applicable:true
  },
  frequency:{
      title: 'Journal Frequency',
      icon: `<svg width="32" height="32"
   viewBox="0 0 32 32" fill="none"
   xmlns="http://www.w3.org/2000/svg">
  <path d="M10.6667 2.66699V6.66699" stroke="#111C42"
        strokeWidth="1.5" strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M21.3333 2.66699V6.66699" stroke="#111C42"
        strokeWidth="1.5" strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M4.66675 12.1201H27.3334" stroke="#111C42"
        strokeWidth="1.5" strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round" />
  <path
      d="M28 11.3337V22.667C28 26.667 26 29.3337 21.3333 29.3337H10.6667C6 29.3337 4 26.667 4 22.667V11.3337C4 7.33366 6 4.66699 10.6667 4.66699H21.3333C26 4.66699 28 7.33366 28 11.3337Z"
      stroke="#111C42" strokeWidth="1.5" strokeMiterlimit="10"
      strokeLinecap="round" strokeLinejoin="round" />
  <path d="M20.9262 18.2663H20.9382" stroke="#F88822"
        strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M20.9262 22.2663H20.9382" stroke="#F88822"
        strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M15.9941 18.2663H16.006" stroke="#F88822"
        strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M15.9941 22.2663H16.006" stroke="#F88822"
        strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M11.059 18.2663H11.071" stroke="#F88822"
        strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M11.059 22.2663H11.071" stroke="#F88822"
        strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round" />
</svg>`,
      dataKey: 'frequency',
      applicable:true
  },
  edition:{
      title: 'Edition',
      icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.3008 2.66699H10.4474C7.60745 2.66699 5.30078 4.98699 5.30078 7.81366V26.6003C5.30078 29.0003 7.02078 30.0137 9.12745 28.8537L15.6341 25.2403C16.3274 24.8537 17.4474 24.8537 18.1274 25.2403L24.6341 28.8537C26.7408 30.027 28.4608 29.0137 28.4608 26.6003V7.81366C28.4474 4.98699 26.1408 2.66699 23.3008 2.66699Z" stroke="#110D3F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.6621 14.6663L15.6621 16.6663L20.9954 11.333" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      dataKey: 'edition',
      dataTextSupport:'',
      applicable:true
  },
  nob:{
    title: 'Number of Books',
    icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.79102 24.0003V9.33366C4.79102 4.00033 6.12435 2.66699 11.4577 2.66699H20.791C26.1243 2.66699 27.4577 4.00033 27.4577 9.33366V22.667C27.4577 22.8537 27.4577 23.0403 27.4443 23.227" stroke="#110D3F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.59102 20H27.4577V24.6667C27.4577 27.24 25.3643 29.3333 22.791 29.3333H9.45768C6.88435 29.3333 4.79102 27.24 4.79102 24.6667V23.8C4.79102 21.7067 6.49768 20 8.59102 20Z" stroke="#110D3F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.791 9.33301H21.4577" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.791 14H17.4577" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
    `,
    dataKey: 'numberOfBooks',
    applicable:true
},
  journalfrequency:{
    title: 'Journal Frequency',
    icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.2546 13.9197L27.9479 19.493C26.8279 24.3064 24.6146 26.253 20.4546 25.853C19.7879 25.7997 19.0679 25.6797 18.2946 25.493L16.0546 24.9597C10.4946 23.6397 8.77459 20.893 10.0813 15.3197L11.3879 9.73302C11.6546 8.59969 11.9746 7.61302 12.3746 6.79969C13.9346 3.57302 16.5879 2.70635 21.0413 3.75969L23.2679 4.27969C28.8546 5.58635 30.5613 8.34635 29.2546 13.9197Z" stroke="#110D3F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.4555 25.8531C19.6288 26.4131 18.5888 26.8798 17.3221 27.2931L15.2155 27.9865C9.92212 29.6931 7.13545 28.2665 5.41545 22.9731L3.70879 17.7065C2.00212 12.4131 3.41545 9.61314 8.70879 7.90647L10.8155 7.21314C11.3621 7.0398 11.8821 6.89314 12.3755 6.7998C11.9755 7.61314 11.6555 8.59981 11.3888 9.73314L10.0821 15.3198C8.77546 20.8931 10.4955 23.6398 16.0555 24.9598L18.2955 25.4931C19.0688 25.6798 19.7888 25.7998 20.4555 25.8531Z" stroke="#110D3F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.2285 11.373L23.6952 13.013" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.9219 16.5332L19.7885 17.5199" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    dataKey: 'journalfrequency',
    applicable:true
},
  isbn:{
      title: 'ISBN No.',
      icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none"
   xmlns="http://www.w3.org/2000/svg">
   <path
       d="M22.6667 27.3337H9.33341C5.33341 27.3337 2.66675 25.3337 2.66675 20.667V11.3337C2.66675 6.66699 5.33341 4.66699 9.33341 4.66699H22.6667C26.6667 4.66699 29.3334 6.66699 29.3334 11.3337V20.667C29.3334 25.3337 26.6667 27.3337 22.6667 27.3337Z"
       stroke="#111C42" strokeWidth="1.5" strokeMiterlimit="10"
       strokeLinecap="round" strokeLinejoin="round" />
   <path d="M8 10.667V21.3337" stroke="#F88822" strokeWidth="1.5"
       strokeMiterlimit="10" strokeLinecap="round"
       strokeLinejoin="round" />
   <path d="M12 10.667V16.0003" stroke="#F88822" strokeWidth="1.5"
       strokeMiterlimit="10" strokeLinecap="round"
       strokeLinejoin="round" />
   <path d="M12 20V21.3333" stroke="#111C42" strokeWidth="1.5"
       strokeMiterlimit="10" strokeLinecap="round"
       strokeLinejoin="round" />
   <path d="M20 10.667V12.0003" stroke="#111C42" strokeWidth="1.5"
       strokeMiterlimit="10" strokeLinecap="round"
       strokeLinejoin="round" />
   <path d="M16 10.667V21.3337" stroke="#F88822" strokeWidth="1.5"
       strokeMiterlimit="10" strokeLinecap="round"
       strokeLinejoin="round" />
   <path d="M20 16V21.3333" stroke="#F88822" strokeWidth="1.5"
       strokeMiterlimit="10" strokeLinecap="round"
       strokeLinejoin="round" />
   <path d="M24 10.667V21.3337" stroke="#F88822" strokeWidth="1.5"
       strokeMiterlimit="10" strokeLinecap="round"
       strokeLinejoin="round" />
</svg>`,
      dataKey: 'isbn',
      applicable:true
  },
  issn:{
      title: 'ISSN NO',
      icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none"
   xmlns="http://www.w3.org/2000/svg">
  <path
      d="M22.6667 27.3337H9.33341C5.33341 27.3337 2.66675 25.3337 2.66675 20.667V11.3337C2.66675 6.66699 5.33341 4.66699 9.33341 4.66699H22.6667C26.6667 4.66699 29.3334 6.66699 29.3334 11.3337V20.667C29.3334 25.3337 26.6667 27.3337 22.6667 27.3337Z"
      stroke="#111C42" strokeWidth="1.5" strokeMiterlimit="10"
      strokeLinecap="round" strokeLinejoin="round" />
  <path d="M8 10.667V21.3337" stroke="#F88822" strokeWidth="1.5"
        strokeMiterlimit="10" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M12 10.667V16.0003" stroke="#F88822" strokeWidth="1.5"
        strokeMiterlimit="10" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M12 20V21.3333" stroke="#111C42" strokeWidth="1.5"
        strokeMiterlimit="10" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M20 10.667V12.0003" stroke="#111C42" strokeWidth="1.5"
        strokeMiterlimit="10" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M16 10.667V21.3337" stroke="#F88822" strokeWidth="1.5"
        strokeMiterlimit="10" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M20 16V21.3333" stroke="#F88822" strokeWidth="1.5"
        strokeMiterlimit="10" strokeLinecap="round"
        strokeLinejoin="round" />
  <path d="M24 10.667V21.3337" stroke="#F88822" strokeWidth="1.5"
        strokeMiterlimit="10" strokeLinecap="round"
        strokeLinejoin="round" />
</svg>`,
      dataKey: 'issn',
      applicable:true
  },
  nop:{
      title: 'No. of Pages',
      icon: `<svg width="32" height="32"
   viewBox="0 0 32 32" fill="none"
   xmlns="http://www.w3.org/2000/svg">
   <path
       d="M28.8801 13.9197L27.5734 19.493C26.4534 24.3064 24.2401 26.253 20.0801 25.853C19.4134 25.7997 18.6934 25.6797 17.9201 25.493L15.6801 24.9597C10.1201 23.6397 8.40008 20.893 9.70674 15.3197L11.0134 9.73302C11.2801 8.59969 11.6001 7.61302 12.0001 6.79969C13.5601 3.57302 16.2134 2.70635 20.6667 3.75969L22.8934 4.27969C28.4801 5.58635 30.1867 8.34635 28.8801 13.9197Z"
       stroke="#111C42" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
   <path
       d="M20.08 25.8531C19.2533 26.4131 18.2133 26.8798 16.9466 27.2931L14.84 27.9865C9.54663 29.6931 6.75997 28.2665 5.03997 22.9731L3.3333 17.7065C1.62663 12.4131 3.03997 9.61314 8.3333 7.90647L10.44 7.21314C10.9866 7.0398 11.5066 6.89314 12 6.7998C11.6 7.61314 11.28 8.59981 11.0133 9.73314L9.70663 15.3198C8.39997 20.8931 10.12 23.6398 15.68 24.9598L17.92 25.4931C18.6933 25.6798 19.4133 25.7998 20.08 25.8531Z"
       stroke="#111C42" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
   <path d="M16.8533 11.373L23.3199 13.013" stroke="#F88822" strokeWidth="1.5"
       strokeLinecap="round"
       strokeLinejoin="round" />
   <path d="M15.5466 16.5332L19.4133 17.5199" stroke="#F88822" strokeWidth="1.5"
       strokeLinecap="round"
       strokeLinejoin="round" />
</svg>`,
      dataKey: 'nop',
      applicable:true
  },
  authors:{
      title: 'Author',
      icon: `<svg width="32" height="32"
   viewBox="0 0 32 32" fill="none"
   xmlns="http://www.w3.org/2000/svg">
   <path
       d="M16.2134 14.4937C16.0801 14.4803 15.9201 14.4803 15.7734 14.4937C12.6001 14.387 10.0801 11.787 10.0801 8.58699C10.0801 5.32033 12.7201 2.66699 16.0001 2.66699C19.2667 2.66699 21.9201 5.32033 21.9201 8.58699C21.9067 11.787 19.3867 14.387 16.2134 14.4937Z"
       stroke="#F88822" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
   <path
       d="M9.54671 19.413C6.32004 21.573 6.32004 25.093 9.54671 27.2397C13.2134 29.693 19.2267 29.693 22.8934 27.2397C26.12 25.0797 26.12 21.5597 22.8934 19.413C19.24 16.973 13.2267 16.973 9.54671 19.413Z"
       stroke="#111C42" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>`,
      dataKey: 'authors',
      applicable:true
  },
  licenseDuration:{
    title: 'License Duration',
    icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.792 2.66699V6.66699" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.458 2.66699V6.66699" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.4583 4.66699C25.8983 4.90699 28.125 6.60033 28.125 12.867V21.107C28.125 26.6003 26.7917 29.347 20.125 29.347H12.125C5.45833 29.347 4.125 26.6003 4.125 21.107V12.867C4.125 6.60033 6.35167 4.92033 10.7917 4.66699H21.4583Z" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.7913 23.4668H4.45801" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.1243 19.6667C19.0699 19.6667 21.4577 17.2789 21.4577 14.3333C21.4577 11.3878 19.0699 9 16.1243 9C13.1788 9 10.791 11.3878 10.791 14.3333C10.791 17.2789 13.1788 19.6667 16.1243 19.6667Z" stroke="#F88822" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.4577 12.667V13.907C16.4577 14.3737 16.2177 14.8137 15.8044 15.0537L14.791 15.667" stroke="#F88822" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    dataKey: 'licenseDuration',
    applicable:true
},
licenseType:{
  title: 'License Type',
  icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M28.875 10.027V21.973C28.875 26.1892 26.875 29 22.2083 29H11.5417C6.875 29 4.875 26.1892 4.875 21.973V10.027C4.875 5.81081 6.875 3 11.5417 3H22.2083C26.875 3 28.875 5.81081 28.875 10.027Z" stroke="#111C42" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.3096 14.6661L12.4265 16.1941L13.6503 16.0824L13.6506 16.0824C13.8915 16.0615 14.1208 16.1918 14.2231 16.4121M13.3096 14.6661L14.6951 17.6053L14.741 17.5259M13.3096 14.6661C13.417 14.763 13.5331 14.8573 13.6586 14.9476M14.2231 16.4121L14.1551 16.4437L14.2231 16.4121L14.2231 16.4121ZM14.2231 16.4121L14.741 17.5259M14.741 17.5259L14.7796 17.609L15.7103 15.9965C15.6851 15.9903 15.66 15.9838 15.6349 15.9771L14.741 17.5259ZM12.5322 13.7155L10.8771 16.5789L22.4668 17.3641C22.6523 17.3803 22.832 17.2932 22.9327 17.1362C23.0333 16.9792 23.038 16.7792 22.9449 16.6177L23.0099 16.5803L23.0099 16.5802L21.3187 13.6434C21.3181 13.6375 21.3168 13.6311 21.3142 13.6243C21.525 13.2966 21.6674 12.9861 21.7614 12.7377C21.8248 12.5703 21.8663 12.4308 21.892 12.3328C21.9049 12.2838 21.9138 12.2451 21.9196 12.2185C21.9225 12.2052 21.9246 12.1949 21.9259 12.1878L21.9275 12.1795L21.9279 12.1773L21.928 12.1766L21.9281 12.1765L21.9281 12.1765L21.9281 12.1764L21.9281 12.1764L21.9282 12.1759L21.9285 12.1743L21.9296 12.1679L21.9338 12.1435C21.9374 12.1224 21.9426 12.0918 21.9488 12.0538C21.9612 11.978 21.9778 11.8729 21.9944 11.7554C22.0273 11.522 22.061 11.2353 22.061 11.0352C22.061 8.21722 19.7654 5.925 16.9438 5.925C14.1259 5.925 11.8336 8.21732 11.8336 11.0352C11.8336 11.2889 11.8864 11.6493 11.938 11.9423C11.9639 12.0896 11.9899 12.2214 12.0093 12.3164C12.0191 12.3639 12.0272 12.4022 12.0329 12.4287L12.0395 12.4592L12.0413 12.4672L12.0414 12.4678L12.0415 12.4684L12.0429 12.4765C12.0441 12.4834 12.0459 12.4935 12.0485 12.5064C12.0537 12.5324 12.0617 12.5699 12.0735 12.6175C12.0969 12.7126 12.1352 12.8476 12.1947 13.0091C12.2694 13.2116 12.3776 13.4561 12.5322 13.7155Z" fill="#F88822" stroke="#F88822" stroke-width="0.15"/>
  <path d="M16.9427 8.20801C15.3837 8.20801 14.1152 9.47643 14.1152 11.0354C14.1152 12.5944 15.3837 13.8629 16.9427 13.8629C18.5017 13.8629 19.7701 12.5944 19.7701 11.0354C19.7701 9.47643 18.5017 8.20801 16.9427 8.20801ZM16.9427 12.864C15.9341 12.864 15.1138 12.0435 15.1138 11.0352C15.1138 10.0269 15.9344 9.20632 16.9427 9.20632C17.951 9.20632 18.7715 10.0266 18.7715 11.0352C18.7715 12.0437 17.9512 12.864 16.9427 12.864Z" fill="#F88822"/>
  <path d="M13.1555 22.4134H21.3287C21.6458 22.4134 21.903 22.1561 21.903 21.8391C21.903 21.5221 21.6458 21.2648 21.3287 21.2648H13.1555C12.8385 21.2648 12.5812 21.5221 12.5812 21.8391C12.5812 22.1561 12.8382 22.4134 13.1555 22.4134Z" fill="#111C42" stroke="#111C42" stroke-width="0.15"/>
  <path d="M25.173 24.2053C25.173 23.8883 24.9158 23.6311 24.5988 23.6311H9.88698C9.56995 23.6311 9.3127 23.8883 9.3127 24.2053C9.3127 24.5224 9.56995 24.7796 9.88698 24.7796H24.599C24.9161 24.7796 25.173 24.5226 25.173 24.2053Z" fill="#111C42" stroke="#111C42" stroke-width="0.15"/>
  </svg>  
  `,
  dataKey: 'licenseType',
  applicable:true
},
subscriptionType:{
  title: 'Subscription Type',
  icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.3749 21.3871C16.4723 21.3882 16.5679 21.3615 16.6507 21.3102L16.6507 21.3102L16.6516 21.3096L30.2141 12.6165L30.2774 12.576L30.2368 12.5128L29.7643 11.7778L29.7238 11.7148L29.6607 11.7552L16.3749 20.2693L3.08914 11.7552L3.02608 11.7148L2.98558 11.7778L2.51308 12.5128L2.47247 12.576L2.5357 12.6165L16.0982 21.3096L16.0982 21.3096L16.0992 21.3102C16.1819 21.3615 16.2776 21.3882 16.3749 21.3871ZM16.3749 21.3871C16.3746 21.3871 16.3743 21.3871 16.3739 21.3871L16.3749 21.3121L16.3759 21.3871C16.3756 21.3871 16.3752 21.3871 16.3749 21.3871ZM30.1737 12.5534L30.1106 12.5939L30.1105 12.5938L30.1737 12.5534ZM30.1737 12.5534L30.1332 12.4902L30.1331 12.4903L30.1737 12.5534ZM2.57617 12.5534L2.61673 12.4903L2.61664 12.4902L2.57617 12.5534ZM2.57617 12.5534L2.63926 12.5939L2.63931 12.5938L2.57617 12.5534Z" fill="#110D3F" stroke="#110D3F" stroke-width="0.15"/>
  <path d="M19.3406 18.6809L19.2846 18.631L19.2347 18.6869L18.6521 19.3397L18.6021 19.3957L18.6581 19.4456L29.1584 28.8175L29.2143 28.8674L29.2643 28.8115L29.8469 28.1587L29.8968 28.1027L29.8409 28.0528L19.3406 18.6809Z" fill="#110D3F" stroke="#110D3F" stroke-width="0.15"/>
  <path d="M13.4603 18.7385L2.95996 28.1104L3.54261 28.7632L14.0429 19.3913L13.4603 18.7385Z" fill="#110D3F"/>
  <path d="M28.31 29.3234H28.3102L30.1737 11.8184L30.2144 11.7553L30.2143 11.7553C30.2143 11.7552 30.2143 11.7552 30.2142 11.7552L26.8238 9.56784L26.7607 9.52711L26.72 9.5903L26.2475 10.3253L26.207 10.3883L26.27 10.4289L29.425 12.463V27.1834C29.425 27.4791 29.3075 27.7627 29.0984 27.9718C28.8893 28.1809 28.6057 28.2984 28.31 28.2984H4.44C4.14428 28.2984 3.86068 28.1809 3.65158 27.9718C3.44247 27.7627 3.325 27.4791 3.325 27.1834V12.463L6.48001 10.4289L6.54297 10.3883L6.50246 10.3253L6.02996 9.5903L5.98934 9.52711L5.92622 9.56784L2.53575 11.7552C2.53573 11.7552 2.53571 11.7553 2.53569 11.7553C2.46354 11.8016 2.40416 11.8652 2.36299 11.9404C2.3218 12.0156 2.30014 12.1 2.3 12.1857V12.1859L2.3 27.1834L2.3 27.1835C2.3012 27.7507 2.52703 28.2943 2.92807 28.6953C3.32911 29.0963 3.87269 29.3222 4.43984 29.3234H4.44H28.31Z" fill="#110D3F" stroke="#110D3F" stroke-width="0.15"/>
  <path d="M18.1326 5.17981L18.1957 5.2205L18.2363 5.15733L18.7088 4.42233L18.7493 4.35934L18.6864 4.31875L16.652 3.00625L16.652 3.00625L16.6519 3.00615C16.5693 2.95316 16.4732 2.925 16.3751 2.925C16.277 2.925 16.1809 2.95316 16.0984 3.00615L16.0982 3.00625L14.0638 4.31875L14.0009 4.35934L14.0414 4.42233L14.5139 5.15733L14.5545 5.2205L14.6176 5.17981L16.3751 4.04663L18.1326 5.17981Z" fill="#110D3F" stroke="#110D3F" stroke-width="0.15"/>
  <path d="M26.875 14.484H26.95V14.409V5.58902C26.95 5.23032 26.8075 4.8863 26.5539 4.63266C26.3002 4.37902 25.9562 4.23652 25.5975 4.23652H7.1525C6.79379 4.23652 6.44978 4.37902 6.19614 4.63266C5.9425 4.8863 5.8 5.23032 5.8 5.58902V14.409V14.484H5.875H6.75H6.825V14.409V5.58902C6.825 5.50216 6.8595 5.41886 6.92092 5.35745C6.98234 5.29603 7.06564 5.26152 7.1525 5.26152H25.5975C25.6844 5.26152 25.7677 5.29603 25.8291 5.35745C25.8905 5.41886 25.925 5.50216 25.925 5.58902V14.409V14.484H26H26.875Z" fill="#110D3F" stroke="#110D3F" stroke-width="0.15"/>
  <path d="M10.675 10.4365V10.5115H10.75H13H13.075V10.4365V8.18652V8.11152H13H10.75H10.675V8.18652V10.4365ZM13.5625 11.4865H10.1875C10.0582 11.4865 9.93421 11.4352 9.84279 11.3437C9.75136 11.2523 9.7 11.1283 9.7 10.999V7.62402C9.7 7.49473 9.75136 7.37073 9.84279 7.27931C9.93421 7.18788 10.0582 7.13652 10.1875 7.13652H13.5625C13.6918 7.13652 13.8158 7.18789 13.9072 7.27931C13.9986 7.37073 14.05 7.49473 14.05 7.62402V10.999C14.05 11.1283 13.9986 11.2523 13.9072 11.3437C13.8158 11.4352 13.6918 11.4865 13.5625 11.4865Z" fill="#F88822" stroke="white" stroke-width="0.15"/>
  <path d="M19.375 8.37402H15.4375C15.3215 8.37402 15.2102 8.32793 15.1281 8.24588C15.0461 8.16384 15 8.05256 15 7.93652C15 7.82049 15.0461 7.70921 15.1281 7.62716C15.2102 7.54512 15.3215 7.49902 15.4375 7.49902H19.375C19.491 7.49902 19.6023 7.54512 19.6844 7.62716C19.7664 7.70921 19.8125 7.82049 19.8125 7.93652C19.8125 8.05256 19.7664 8.16384 19.6844 8.24588C19.6023 8.32793 19.491 8.37402 19.375 8.37402Z" fill="#F88822"/>
  <path d="M22 10.5615H15.4375C15.3215 10.5615 15.2102 10.5154 15.1281 10.4334C15.0461 10.3513 15 10.2401 15 10.124C15 10.008 15.0461 9.89671 15.1281 9.81466C15.2102 9.73262 15.3215 9.68652 15.4375 9.68652H22C22.116 9.68652 22.2273 9.73262 22.3094 9.81466C22.3914 9.89671 22.4375 10.008 22.4375 10.124C22.4375 10.2401 22.3914 10.3513 22.3094 10.4334C22.2273 10.5154 22.116 10.5615 22 10.5615Z" fill="#F88822"/>
  <path d="M23.3245 13.9365H9.98551C9.8899 13.9365 9.7982 13.8904 9.73059 13.8084C9.66298 13.7263 9.625 13.6151 9.625 13.499C9.625 13.383 9.66298 13.2717 9.73059 13.1897C9.7982 13.1076 9.8899 13.0615 9.98551 13.0615H23.3245C23.4201 13.0615 23.5118 13.1076 23.5794 13.1897C23.647 13.2717 23.685 13.383 23.685 13.499C23.685 13.6151 23.647 13.7263 23.5794 13.8084C23.5118 13.8904 23.4201 13.9365 23.3245 13.9365Z" fill="#F88822"/>
  </svg>    
  `,
  dataKey: 'subscriptionType',
  applicable:true
},
subscriptionDuration:{
  title: 'Subscription Duration',
  icon: `
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.792 2.66699V6.66699" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.458 2.66699V6.66699" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.4583 4.66699C25.8983 4.90699 28.125 6.60033 28.125 12.867V21.107C28.125 26.6003 26.7917 29.347 20.125 29.347H12.125C5.45833 29.347 4.125 26.6003 4.125 21.107V12.867C4.125 6.60033 6.35167 4.92033 10.7917 4.66699H21.4583Z" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.7913 23.4668H4.45801" stroke="#110D3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.1243 19.6667C19.0699 19.6667 21.4577 17.2789 21.4577 14.3333C21.4577 11.3878 19.0699 9 16.1243 9C13.1788 9 10.791 11.3878 10.791 14.3333C10.791 17.2789 13.1788 19.6667 16.1243 19.6667Z" stroke="#F88822" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4577 12.667V13.907C16.4577 14.3737 16.2177 14.8137 15.8044 15.0537L14.791 15.667" stroke="#F88822" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
   
  `,
  dataKey: 'subscriptionDuration',
  applicable:true
},
  weight:{
      title: 'Weight',
      icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.10156 11.92L16.8749 16.7334L25.5682 11.96" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.875 25.8133V16.72" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.1158 3.30672L6.99583 7.25338C5.3825 8.14672 4.0625 10.3867 4.0625 12.2267V19.76C4.0625 21.6 5.3825 23.84 6.99583 24.7334L14.1158 28.6934C15.6358 29.5334 18.1292 29.5334 19.6492 28.6934L26.7692 24.7334C28.3825 23.84 29.7025 21.6 29.7025 19.76V12.2267C29.7025 10.3867 28.3825 8.14672 26.7692 7.25338L19.6492 3.29338C18.1158 2.45338 15.6358 2.45338 14.1158 3.30672Z" stroke="#111C42" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      dataKey: 'weight',
      dataTextSupport: 'kg',
      applicable:true
  },
  binding:{
      title: 'Binding Type',
      icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M4.66663 24.0001V9.33341C4.66663 4.00008 5.99996 2.66675 11.3333 2.66675H20.6666C26 2.66675 27.3333 4.00008 27.3333 9.33341V22.6667C27.3333 22.8534 27.3333 23.0401 27.32 23.2267" stroke="#111C42" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
           <path d="M8.46663 20H27.3333V24.6667C27.3333 27.24 25.24 29.3333 22.6666 29.3333H9.33329C6.75996 29.3333 4.66663 27.24 4.66663 24.6667V23.8C4.66663 21.7067 6.37329 20 8.46663 20Z" stroke="#111C42" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
           <path d="M10.6666 9.33325H21.3333" stroke="#F88822" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
           <path d="M10.6666 14H17.3333" stroke="#F88822" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
       </svg>`,
      dataKey: 'binding',
      applicable:true
  },
  language:{
      title: 'Language',
      icon: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2905 4H12.6238C10.0238 11.7867 10.0238 20.2133 12.6238 28H11.2905" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.625 4C23.225 11.7867 23.225 20.2133 20.625 28" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.625 21.3333V20C12.4117 22.6 20.8383 22.6 28.625 20V21.3333" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.625 11.9998C12.4117 9.3998 20.8383 9.3998 28.625 11.9998" stroke="#F88822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.6243 29.3337C23.9881 29.3337 29.9577 23.3641 29.9577 16.0003C29.9577 8.63653 23.9881 2.66699 16.6243 2.66699C9.26055 2.66699 3.29102 8.63653 3.29102 16.0003C3.29102 23.3641 9.26055 29.3337 16.6243 29.3337Z" stroke="#110D3F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      dataKey: 'language',
      applicable:false
  },
}

export const DeviceType = {
	MOBILE: "MOBILE",
	TABLET: "TABLET",
	DESKTOP: "DESKTOP",
	ANDROID: "ANDROID",
	MOBILE_WEB: "MOBILE_WEB",
  UNKNOWN: "UNKNOWN"
};
export const sharableBookStoreUrlSeparator = "-|-";

export const environments: any = {
  "development": {
      "REACT_APP_NODE_ENV" : "development",
      "REACT_APP_NODE_BOOKSTORE_BASE_URL": "https://devapi.taxmann.dev/bookstore/api/v1/",
      "REACT_APP_NODE_DASHBOARD_BASE_URL": "https://devapi.taxmann.dev/dashboard/api/v1/",
      "REACT_APP_DOT_NET_BASE_URL": "https://lbackoffice.taxmann.com/taxmannapimysql/api/",
      "REACT_APP_PRODUCT_ATTACHMENT_BASE_URL": "https://cdn.taxmann.com/BookshopFiles/",
      "REACT_APP_NETCORE_REGISTER_KEY": "b2634270df9910921e238f82be217d9f",
      "REACT_APP_ENDMILE_API_SERVER": "https://devapi.taxmann.dev/msc/api/v1/endmile/",
      "REACT_APP_IMAGE_URL": "https://d3lzbbhyvqc4k.cloudfront.net/images/",
      "REACT_APP_WEBP_IMAGE_URL": "https://d3lzbbhyvqc4k.cloudfront.net/images-webp/",
      "REACT_APP_REDIS_URL": "https://nodedev.taxmann.com/research_module/",
      "REACT_APP_RAZORPAY_KEY": "rzp_test_qxZW6zdMVeKlpf",
      "REACT_APP_STUDENT_BASE_URL": "https://devapi.taxmann.dev/students/api/v1/",
      "REACT_APP_MARKETING_API": "https://devapi.taxmann.dev/marketing/api/v1/marketing/api/v1/",
      "REACT_APP_SCCRET_KEY_MACID": "dsdgjSWDhjKEYGEN232xsdf5Asf5Op"
  },
  "staging": {
      "REACT_APP_NODE_ENV" : "staging",
      "REACT_APP_NODE_BOOKSTORE_BASE_URL": "https://preprodapi.taxmann.com/bookstore/",
      "REACT_APP_NODE_DASHBOARD_BASE_URL": "https://preprodapi.taxmann.com/bookstore-dashboard/",
      "REACT_APP_DOT_NET_BASE_URL": "https://stagingapi.taxmann.com/api/",
      "REACT_APP_PRODUCT_ATTACHMENT_BASE_URL": "https://cdn.taxmann.com/BookshopFiles/",
      "REACT_APP_NETCORE_REGISTER_KEY": "b2634270df9910921e238f82be217d9f",
      "REACT_APP_ENDMILE_API_SERVER": "https://devapi.taxmann.dev/msc/api/v1/endmile/",
      "REACT_APP_IMAGE_URL": "https://d3lzbbhyvqc4k.cloudfront.net/images/",
      "REACT_APP_WEBP_IMAGE_URL": "https://d3lzbbhyvqc4k.cloudfront.net/images-webp/",
      "REACT_APP_REDIS_URL": "https://testapi.taxmann.com/research_module/",
      "REACT_APP_RAZORPAY_KEY": "rzp_test_qxZW6zdMVeKlpf",
      "REACT_APP_STUDENT_BASE_URL": "https://preprodapi.taxmann.com/students/",
      "REACT_APP_MARKETING_API": "https://stagapi.taxmann.dev/marketing/api/v1/",
      "REACT_APP_SCCRET_KEY_MACID": "dsdgjSWDhjKEYGEN232xsdf5Asf5Op"
  },
  "production": {
      "REACT_APP_NODE_ENV" : "production",
      "REACT_APP_NODE_BOOKSTORE_BASE_URL": "https://prodapibookstore.taxmann.com/bookstore/",
      "REACT_APP_NODE_DASHBOARD_BASE_URL": "https://prodapibookstore.taxmann.com/bookstore-dashboard/",
      "REACT_APP_DOT_NET_BASE_URL": "https://liveresearchapi.taxmann.com/api/",
      "REACT_APP_PRODUCT_ATTACHMENT_BASE_URL": "https://cdn.taxmann.com/BookshopFiles/",
      "REACT_APP_NETCORE_REGISTER_KEY" : "0ec5ef80e56cf82f8a6b71d2bb8d5cca",
      "REACT_APP_ENDMILE_API_SERVER":"https://stagapi.taxmann.dev/msc/api/v1/endmile/",
      "REACT_APP_IMAGE_URL": "https://d3lzbbhyvqc4k.cloudfront.net/images/",
      "REACT_APP_WEBP_IMAGE_URL": "https://d3lzbbhyvqc4k.cloudfront.net/images-webp/",
      "REACT_APP_REDIS_URL": "https://api.taxmann.com/research_module/",
      "REACT_APP_RAZORPAY_KEY": "rzp_live_o2Fh5USXaEgp8b",
      "REACT_APP_NODE_CA_CS_BASE_URL":"https://prodapistudents.taxmann.com/students/",
      "REACT_APP_MARKETING_API":"https://prodapi.taxmann.com/marketing/api/v1/",
      "REACT_APP_STUDENT_BASE_URL": "https://prodapistudents.taxmann.com/students/",
      "REACT_APP_SCCRET_KEY_MACID": "dsdgjSWDhjKEYGEN232xsdf5Asf5Op"
  }
}

export const monthsArrayConst= [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' }
];
export const AUTHOR_ROUTE = '/bookstore/authors/'

export const ProductSchemas:any = {
 "40000310": `{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Life of a CA Student: 40% Pass but 49% Fail",
  "image": "https://cdn.taxmann.com/BookshopFiles/Bookimg/2S.gif",
  "description": "The book is about the life of a chartered accountant (CA) student. The story starts from the day the protagonist decides to pursue CA and ends when he gets qualified as a CA. The protagonist describes his pains and pleasures while pursuing the CA course - a more than 4 years-long journey. The mistakes and failures of the characters in the story are described in an analytical way to help CA students and aspirants of the CA course. This book contains a score of concepts of academic importance. They are expressed in a more simplified way to benefit CA students in particular and commerce students in general. This is the story of the author himself but it's been fictionalized to a certain extent to justify the overall objective of the book.",
  "sku": "9788171946228",
  "brand": {
    "@type": "Brand",
    "name": "Taxmann"
  },
  "offers": {
    "@type": "AggregateOffer",
    "offerCount": "2",
    "lowPrice": "142",
    "highPrice": "190",
    "priceCurrency": "INR",
    "offers": [
      {
        "@type": "Offer",
        "url": "https://www.taxmann.com/bookstore/product/40000310-life-of-a-ca-student-40-pass-but-49-fail",
        "priceCurrency": "INR",
        "price": "190",
        "priceValidUntil": "2024-12-31",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "Taxmann"
        }
      },
      {
        "@type": "Offer",
        "url": "https://www.taxmann.com/bookstore/product/40000310-life-of-a-ca-student-40-pass-but-49-fail",
        "priceCurrency": "INR",
        "price": "142",
        "priceValidUntil": "2024-12-31",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "Taxmann"
        }
      }
    ]
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "reviewCount": "1"
  }
}
  {
    "@context": "https://schema.org",
    "@type": "Book",
    "name": "Life of a CA Student: 40% Pass but 49% Fail",
    "image": "https://cdn.taxmann.com/BookshopFiles/Bookimg/2S.gif",
    "description": "The book is on the life of a Chartered Accountant (CA) student. The story starts from the day the protagonist decides to pursue CA and ends at the time he gets qualified as a CA. The protagonist describes his pains and pleasures while pursuing the CA course - a journey of more than 4 years. The mistakes and failures of the characters in the story are described in an analytical way to help CA students and aspirants of the CA course. This book contains a score of concepts of academic importance. They are expressed in a more simplified way for the benefit of CA students in particular and commerce students in general. This is the story of the author himself but it's been fictionalized to a certain extent to justify the overall objective of the book.",
    "isbn": "9788171946228",
    "author": {
      "@type": "Organization",
      "name": "Taxmann"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Taxmann"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://www.taxmann.com/bookstore/product/40000310-life-of-a-ca-student-40-pass-but-49-fail",
      "priceCurrency": "INR",
      "price": "190",
      "priceValidUntil": "2024-12-31",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "Taxmann"
      }
    }
}`,
"40000375":`{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Debt Collections",
  "image":
"https://cdn.taxmann.com/BookshopFiles/Bookimg/debtcollection2011small.jpg",
  "description": "This book provides a comprehensive guide to debt collection, covering legal frameworks, practical strategies, and ethical considerations. It is designed to assist professionals in managing debt recovery processes efficiently.",
  "sku": "9788171947928",
  "brand": {
    "@type": "Brand",
    "name": "Taxmann"
  },
  "offers": {
    "@type": "Offer",
    "url": "https://www.taxmann.com/bookstore/product/40000375-debt-collections",
    "priceCurrency": "INR",
    "price": "387",
    "priceValidUntil": "2024-12-31",
    "itemCondition": "https://schema.org/NewCondition",
    "availability": "https://schema.org/InStock",
    "seller": {
      "@type": "Organization",
      "name": "Taxmann"
    },
    "priceSpecification": {
      "@type": "PriceSpecification",
      "price": "387",
      "priceCurrency": "INR",
      "eligibleQuantity": {
        "@type": "QuantitativeValue",
        "value": 1,
        "unitText": "unit"
      },
      "listPrice": "395"
    }
  }
}
  {
    "@context": "https://schema.org",
    "@type": "Book",
    "name": "Debt Collections",
    "image": "https://cdn.taxmann.com/BookshopFiles/Bookimg/debtcollection2011small.jpg",
    "description": "This book provides a comprehensive guide to debt collection, covering legal frameworks, practical strategies, and ethical considerations. It is designed to assist professionals in managing debt recovery processes efficiently.",
    "isbn": "9788171947928",
    "author": {
      "@type": "Person",
      "name": "Steven F. Coyle"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Taxmann"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://www.taxmann.com/bookstore/product/40000375-debt-collections",
      "priceCurrency": "INR",
      "price": "387",
      "priceValidUntil": "2024-12-31",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "Taxmann"
      }
    }
}`,
"40000353":`{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Business in Crisis",
  "image":  "https://cdn.taxmann.com/BookshopFiles/Bookimg/Business in crisis_[300x425].jpg ",
  "description": "This book offers insights into managing a business during a crisis, covering strategies for survival, adaptation, and growth in challenging times. It is an essential resource for business professionals and leaders facing uncertain environments.",
  "sku": "9788171947904",
  "brand": {
    "@type": "Brand",
    "name": "Taxmann"
  },
  "offers": {
    "@type": "Offer",
    "url": "https://www.taxmann.com/bookstore/product/40000353-business-in-crisis",
    "priceCurrency": "INR",
    "price": "175",
    "priceValidUntil": "2024-12-31",
    "itemCondition": "https://schema.org/NewCondition",
    "availability": "https://schema.org/InStock",
    "seller": {
      "@type": "Organization",
      "name": "Taxmann"
    }
  }
}
  {
    "@context": "https://schema.org",
    "@type": "Book",
    "name": "Business in Crisis",
    "image": "https://cdn.taxmann.com/BookshopFiles/Bookimg/Business in crisis_[300x425].jpg",
    "description": "This book offers insights into managing a business during a crisis, covering strategies for survival, adaptation, and growth in challenging times. It is an essential resource for business professionals and leaders facing uncertain environments.",
    "isbn": "9788171947904",
    "author": {
      "@type": "Person",
      "name": "C. Vasanta Madhavi"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Taxmann"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://www.taxmann.com/bookstore/product/40000353-business-in-crisis",
      "priceCurrency": "INR",
      "price": "175",
      "priceValidUntil": "2024-12-31",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "Taxmann"
      }
    }
}`
}